import { EngageCampaignEndpoints } from '@src/client/v2/modules/engage/campaign/api/endpoints';
import { FlexicastEndpoints } from '@src/client/v2/modules/engage/flexicast/api/endpoints';
import { GlobalLimitsEndpoints } from '@src/client/v2/modules/global-limits/api/constants';
import { UserProfileEndpoints } from '@src/client/v2/modules/user-profiles/api/constants';

export const API_PAGINATION_PAGE_SIZE = 10;

export enum Cookie {
  refresh_token = '_refresh_token',
  id_token = '_id_token',
  next = '_next',
  workspace_id = '_workspace_id',
  tenant_id = '_tenant_id',
  auth_type = '_auth_type',
}

export enum UserInfoEndpoints {
  userScopesList = '/user/accessScopes',
  userInfo = '/user/current',
  updateLoginTenant = '/auth/setTenant',
  allUsers = '/user/all',
  getUsers = '/user/list',
  usersCount = '/user/count',
  role = '/user/{user_id}/role',
  deleteUser = '/user/{user_id}/delete',
  base = '/user/',
  filteredUsers = '/user/search',
}

export enum RoleEndpoints {
  getRoles = '/roles/',
}

export enum TeamEndpoints {
  base = '/teams/',
  teamUsers = '/teams/{team_id}/users',
  teamUsersCount = '/teams/{team_id}/users/count',
  team = '/teams/{team_id}',
  count = '/teams/count',
}

export enum DashboardEndpoints {
  dashboardSummariesList = '/dashboard',
  dashboardByItemExtId = '/dashboard/{item_ext_id}',
  createDashboard = '/dashboard',
  updateDashboard = '/dashboard/{item_ext_id}',
  dashboardCount = '/dashboard/count',
}

export enum ReportEndpoints {
  dimensions = `/dimensions/listDetailed`,
  dimensionValues = '/dimensions/values/{dimension}',
  searchDimensionValues = '/dimensions/search/{dimension}/{searchString}',
  reportList = '/report/all',
  createReport = '/report/item',
  initiateRun = '/report/run',
  reportSummariesList = '/report',
  insightByItemExtId = '/report/{item_ext_id}',
  reportCount = '/report/count',
  duplicateReport = '/report/duplicate',
  bulk = '/report/bulk',
}

export enum LlmEndpoints {
  llmItem = `/report/llm/item`,
  updateLlmFeedback = `/report/llm/feedback`,
}

export enum CohortEndpoints {
  cohortList = '/cohort/all',
  createCohort = '/cohort/item',
  initiateRun = '/cohort/run',
  cohortSummariesList = '/cohort',
  cohortByItemExtId = '/cohort/{item_ext_id}',
  basicList = '/cohort/list',
  cohortCount = '/cohort/count',
  cohortUserCount = '/cohort/{item_ext_id}/count',
}

export enum UserEndpoints {
  myReports = '/user/my_reports',
  recentItems = '/user/recents',
  login = '/auth/login',
  refresh = '/auth/refresh',
  userInfo = '/user/',
  authorize = '/auth/authorize',
  signup = '/auth/signup',
  updateLastActiveWorkspaceId = '/user/last_active_workspace',
  recentlyViewed = '/user/recently-viewed',
}

export enum FunnelEndpoints {
  funnelSummariesList = '/funnel',
}

export enum RunEndpoints {
  runStatus = '/run/{runId}',
  runResult = '/run/result',
}

export enum DataItemEndpoints {
  userInfo = '/data_platform/users/getUserInfo',
}

export enum SearchEndpoints {
  searchItems = '/search',
}

export enum EventEndpoints {
  eventByItem = '/event/item',
  eventByItemExtId = '/event/item/{item_ext_id}',
  eventProperties = '/event/properties',
}

export enum AlertEndpoints {
  alertsForReport = '/report/alerts',
  alertCountForReport = '/report/alerts/count',
  anomaliesForReport = '/report/anomalies',
  addAlert = '/alert/item',
  byItemExtId = '/alert/{item_ext_id}',
  byItemId = '/alert/item/{item_id}',
  summaryByItemId = '/alert/item/{item_id}/summary',
  alertList = '/alert/list',
  alertListCount = '/alert/list/count',
  byAnomalyId = '/alert/anomaly/{anomaly_id}',
  updateDashboardReportList = '/alert/update/{item_ext_id}/dashboardReportList',
}

export enum CustomEventsEndpoints {
  count = '/custom/count',
  customEventsList = '/custom',
  eventByItemExtId = '/custom/{item_ext_id}',
  create = '/custom',
}

export type APIEndpoints =
  | UserInfoEndpoints
  | RoleEndpoints
  | TeamEndpoints
  | DataItemEndpoints
  | ReportEndpoints
  | RunEndpoints
  | UserEndpoints
  | CohortEndpoints
  | DashboardEndpoints
  | SearchEndpoints
  | EventEndpoints
  | AlertEndpoints
  | CustomEventsEndpoints
  | LlmEndpoints
  | FlexicastEndpoints
  | EngageCampaignEndpoints
  | GlobalLimitsEndpoints
  | UserProfileEndpoints;

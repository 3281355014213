/* eslint-disable react/no-children-prop */
import { activeWorkspaceState } from '@src/client/recoil/atoms';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery } from 'react-query';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ErrorTags } from '../lib/analytics/events';
import Tracker from '../lib/analytics/tracker';
import { Cookie } from '../lib/api/constants';
import { AuthError } from '../lib/api/errors';
import { updateLastActiveWorkspaceId } from '../lib/api/mutations/common';
import { getUserInfoOld } from '../lib/api/queries/common';
import { UserInfo, Workspace } from '../lib/api/types/response';
import { saveActiveWorkspaceDetailsInLocalStorage, setLastVisitedpath } from '../lib/utils';
import Welcome from '../pages/Welcome';
import HomeScreen from '../v2/modules/home';
import { SpinLoader } from '../v2/ui-library/loaders';
import { ALL_ROUTES } from './data';
import ProtectedRoute from './ProtectedRoute';
import UnauthorizedAccess from './UnauthorizedAccess';

const NoMatch = React.lazy(() => import('@src/client/pages/404'));
const Login = React.lazy(() => import('@src/client/pages/Login'));

const ACCESS_SPECIFIC_ROUTES = ALL_ROUTES.map((routeData) => {
  const hasSubroutes = Array.isArray(routeData.subRoutes) && routeData.subRoutes.length > 0;
  const baseRoute = hasSubroutes ? <Outlet /> : routeData.component;
  return (
    <Route
      path={`${routeData.path}`}
      key={routeData.path}
      element={
        <ProtectedRoute
          children={<UnauthorizedAccess children={baseRoute} routeAccessSelector={routeData.selector} />}
        />
      }
    >
      {hasSubroutes ? (
        <>
          <Route
            index
            element={
              <ProtectedRoute
                children={
                  <UnauthorizedAccess children={routeData.component} routeAccessSelector={routeData.selector} />
                }
              />
            }
          />
          {routeData.subRoutes?.map((subRoute) => (
            <Route
              path={subRoute.path}
              key={subRoute.path}
              element={
                <ProtectedRoute
                  children={
                    <UnauthorizedAccess children={subRoute.component} routeAccessSelector={routeData.selector} />
                  }
                />
              }
            />
          ))}
          <Route path="*" element={<NoMatch />} />
        </>
      ) : null}
    </Route>
  );
});

function Router() {
  const [activeWorkspace, setActiveWorkspace] = useRecoilState(activeWorkspaceState);
  const [cookies] = useCookies([Cookie.id_token]);
  const navigate = useNavigate();
  const [isWorkspaceInfoLoading, setIsWorkspaceInfoLoading] = useState(true);

  const updateActiveWorkspaceRequest = useMutation(updateLastActiveWorkspaceId);

  const updateWorkspaceToUseOnClient = (wsToUse: Workspace) => {
    setActiveWorkspace(wsToUse);
    saveActiveWorkspaceDetailsInLocalStorage(wsToUse.id, wsToUse.tenantId);
  };

  const asyncActiceWorspaceMutation = (wsToUse: Workspace, changingFrom?: string) => {
    updateActiveWorkspaceRequest
      .mutateAsync({ lastActiveWorkspaceId: wsToUse.id })
      .then((_res) => {
        updateWorkspaceToUseOnClient(wsToUse);
      })
      .catch((error) => {
        Tracker.trackError(error, ErrorTags.ACTIVE_WORKSPACE_UPDATE_ERROR, {
          workspaceToUpdate: wsToUse.id,
          changingFrom,
        });
      });
  };

  const doActiveWorkspaceValidation = (data: UserInfo) => {
    // Workspace id in local storage
    // const workspaceIdInLocalStorage = localStorage.getItem(Cookie.workspace_id);

    // Workspace id in url
    const pathArr = window.location.pathname.split('/');
    const workspaceIdInUrl = pathArr[1];
    let workspaceDataIndex = data?.workspaces?.findIndex((ws) => ws.id === workspaceIdInUrl);

    const serverActiveWorkspaceId = data?.activeWorkspaceId;

    // url workspace id found in workspaces list
    if (workspaceDataIndex > -1) {
      const wsToUse = data?.workspaces[workspaceDataIndex];
      if (serverActiveWorkspaceId !== workspaceIdInUrl) {
        // Update remote active workspace id
        asyncActiceWorspaceMutation(wsToUse, 'URL');
        return;
      }
      // update local active workspace info and resume workflow
      setActiveWorkspace(wsToUse);
      saveActiveWorkspaceDetailsInLocalStorage(wsToUse.id, wsToUse.tenantId);
    } else {
      // url workspace id not found in workspaces list

      // use the remote active workspace info if available
      workspaceDataIndex = data?.workspaces?.findIndex((ws) => ws.id === serverActiveWorkspaceId);
      if (workspaceDataIndex > -1) {
        // use remote active workspace info only if it matches with one of the available workspaces
        const wsToUse = data?.workspaces[workspaceDataIndex];
        setActiveWorkspace(wsToUse);
        saveActiveWorkspaceDetailsInLocalStorage(wsToUse.id, wsToUse.tenantId);
      } else {
        // maybe the remote workspace ingo is corrupt as data not found in workspace info list. use the first one from available data
        const wsToUse = data?.workspaces[0];
        asyncActiceWorspaceMutation(wsToUse, 'DEFAULT_WORKSPACE_LIST');
      }
    }
  };

  const { isLoading: isUserInfoLoading } = useQuery(['userInfo', {}], getUserInfoOld, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    retry: 1,
    enabled: cookies[Cookie.id_token] !== undefined && sessionStorage.getItem('first_time_user') !== 'true',
    onSuccess: doActiveWorkspaceValidation,
    onError: (error) => {
      console.error('An error occurred:', error);
      if (error instanceof AuthError) {
        setLastVisitedpath();
        navigate('/login');
      }
    },
  });

  useEffect(() => {
    if (isUserInfoLoading || updateActiveWorkspaceRequest.isLoading) {
      setIsWorkspaceInfoLoading(true);
    } else {
      setIsWorkspaceInfoLoading(false);
    }
  }, [isUserInfoLoading, updateActiveWorkspaceRequest.isLoading]);

  if (isWorkspaceInfoLoading) {
    return (
      <div className="flex items-center justify-center h-layout-v2">
        <SpinLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={activeWorkspace ? `/${activeWorkspace.id}/home` : '/login'} replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path=":workspaceId" element={<Outlet />}>
        <Route
          index
          path="home"
          element={
            <ProtectedRoute>
              <UnauthorizedAccess routeAccessSelector="query_run">
                <HomeScreen />
              </UnauthorizedAccess>
            </ProtectedRoute>
          }
        />
        <Route path="" element={<NoMatch />} />
        {ACCESS_SPECIFIC_ROUTES}
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Router;

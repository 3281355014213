/* eslint-disable no-restricted-syntax */
import { useRecoilSnapshot } from 'recoil';

export default function RecoilDebugObserver() {
  const snapshot = useRecoilSnapshot();
  // useEffect(() => {
  //   console.log('The following atoms were modified:');
  //   const nodes = snapshot.getNodes_UNSTABLE({ isModified: true });
  //   for (const node of nodes) {
  //     console.log(node.key, snapshot.getLoadable(node));
  //   }
  // }, [snapshot]);

  return null;
}

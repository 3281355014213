import { HomeIcon, YourBoardsIcon, YourReportsIcon } from '../../ui-library/icons/HomepageIcons';
import { HomeNavItemType } from './types';

export const RECENTLY_VIEWED_PAGE_SIZE = 50;
export const SEARCH_PAGE_SIZE = 100;

export enum HomeScreenNavItemKey {
  Home = 'Home',
  YourReports = 'YourReports',
  YourBoards = 'YourBoards',
}

export const HomeScreenNavItems = [
  HomeScreenNavItemKey.Home,
  HomeScreenNavItemKey.YourReports,
  HomeScreenNavItemKey.YourBoards,
];

export const HomeScreenNavItemData: Record<HomeScreenNavItemKey, HomeNavItemType> = {
  [HomeScreenNavItemKey.Home]: {
    title: 'Home',
    icon: <HomeIcon />,
    path: 'home',
  },
  [HomeScreenNavItemKey.YourReports]: {
    title: 'Your Reports',
    icon: <YourReportsIcon />,
    path: 'my-reports',
  },
  [HomeScreenNavItemKey.YourBoards]: {
    title: 'Your Boards',
    icon: <YourBoardsIcon />,
    path: 'dashboard',
  },
};

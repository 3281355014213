/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './index.css';

import { createRoot } from 'react-dom/client';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

/* eslint-disable react/no-unstable-nested-components */
import { RecentlyViewedItemResponse } from '@src/client/lib/api/types/response';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { getNavigationPath } from '@src/client/lib/utils';
import { ReportTypeDisplyInfo } from '@src/client/v2/helpers/constants';
import { Skeleton } from '@src/client/v2/ui-library/skeleton';
import { Link } from 'react-router-dom';
import { VirtuosoGrid } from 'react-virtuoso';

interface Props {
  data: RecentlyViewedItemResponse[];
  loading?: boolean;
  useWindowScroll?: boolean;
  onEndReached?: (index: number) => void;
}

const SampleImages = [
  '/images/v2/report-cards/line-graph-img-2.png',
  '/images/v2/report-cards/bar-graph-img.png',
  '/images/v2/report-cards/flow-graph-img.png',
  '/images/v2/report-cards/line-graph-img.png',
];
export default function ReportsGrid({ data, useWindowScroll, loading, onEndReached }: Props) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  return (
    <div className="py-2 border border-border ">
      <VirtuosoGrid
        className="box-border"
        useWindowScroll={useWindowScroll}
        endReached={onEndReached}
        totalCount={data.length}
        overscan={30}
        components={{
          ScrollSeekPlaceholder: () => <Skeleton className="w-[30%] h-[170px] my-2 rounded-xl" />,
        }}
        itemContent={(index) => {
          const reportData = data[index];

          return (
            <Link
              to={getLinkWithWorkspace(
                getNavigationPath(reportData.itemType, reportData.reportType, reportData.itemExternalId),
              )}
            >
              <div className="border border-border rounded-xl px-2 py-3">
                <img src={SampleImages[index % 4]} alt="random graph icon" className="w-full mb-4" />
                <div className="flex items-center">
                  <img
                    className="h-8 w-8"
                    src={
                      reportData.reportType && ReportTypeDisplyInfo[reportData.reportType]
                        ? ReportTypeDisplyInfo[reportData.reportType].imageSrc
                        : '/images/v2/dashboard-small.png'
                    }
                    alt="report icon"
                  />
                  <div className="ml-2 max-w-[75%]">
                    <p className="text-xs font-medium truncate">{reportData.name}</p>
                    <p className="text-xs font-regular truncate max-w-300">{reportData.description}</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        }}
      />
      <style>{`.virtuoso-grid-list {display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); row-gap: 24px; column-gap: 12px; padding: 0 16px;}`}</style>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { getSearchCreatorItems } from '@src/client/lib/api/queries/common';
import { SearchCreatorItem } from '@src/client/lib/api/types/response';
import { SingleUserIcon } from '@src/client/v2/ui-library/icons/DashboardIcons';
import { Select, SelectOptionsType } from '@src/client/v2/ui-library/select';
import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { createdByFilterState } from './state';

export const useCreators = () => {
  const [creators, setCreators] = useState<SearchCreatorItem[]>([]);
  const [creatorDropdownOptions, setCreatorDropdownPptions] = useState<SelectOptionsType[]>([]);

  const searchCreatorSummariesRequest = useMutation(getSearchCreatorItems, {
    onSuccess: (response) => {
      const uniqueRes = uniqBy(response, (r) => r.userId);
      setCreators([...uniqueRes]);
      setCreatorDropdownPptions(uniqueRes.map((res) => ({ label: res.email, value: res.userId })));
    },
    onError: (error: Error, variables) => {
      console.error('An error has occured while trying to fetch creators', error);
      Tracker.trackError(error, ErrorTags.SEARCH_CREATOR_FETCH_ERROR, {
        filterText: variables?.filterText,
        limit: variables?.limit,
        offset: variables?.offset,
      });
    },
  });

  useEffect(
    () =>
      searchCreatorSummariesRequest.mutate({
        filterText: '',
        limit: 1000,
        offset: 0,
      }),
    [],
  );

  return {
    isLoading: searchCreatorSummariesRequest.isLoading,
    error: searchCreatorSummariesRequest.error,
    creators,
    creatorDropdownOptions,
  };
};

const CreatedByFilterPlaceholder = React.memo(() => (
  <div className="flex items-center justify-start">
    <SingleUserIcon />
    <p className="ml-2">Created By</p>
  </div>
));

CreatedByFilterPlaceholder.displayName = 'CreatedByFilterPlaceholder';

export default function CreatedByFilter() {
  const { creatorDropdownOptions, isLoading } = useCreators();
  const [selectedCreator, setSelectedCreators] = useRecoilState(createdByFilterState);
  const resetSelectedCreators = useResetRecoilState(createdByFilterState);

  const handleOptionSelection = useCallback((val: any, meta: any) => {
    setSelectedCreators(val as SelectOptionsType[]);
  }, []);

  useEffect(() => () => resetSelectedCreators(), []);

  return (
    <>
      <p className="mb-2 text-sm font-semibold">Created By</p>
      <Select
        options={creatorDropdownOptions}
        isLoading={isLoading}
        value={selectedCreator}
        menuPosition="fixed"
        isMulti
        onChange={handleOptionSelection}
        placeholder={<CreatedByFilterPlaceholder />}
      />
    </>
  );
}

import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { activeWorkspaceState } from '../recoil/atoms';

export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useNavigationLinkWithWorkspace() {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);

  const getLinkWithWorkspace = (path: string) => `/${activeWorkspace?.id}/${path}`;

  return {
    getLinkWithWorkspace,
  };
}

export function useEffectAfterMount(cb: EffectCallback, dependencies: DependencyList | undefined) {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;

    return () => {};
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

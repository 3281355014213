import { insightReportRowState } from '@features/reports/atoms';
import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/v2/components/filters-and-selectors/date-range-selector/state';
import { eventPropertyListState, eventTableColumnListState } from '@src/client/v2/modules/events/state';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
  ChartType,
  DateRangeEnum,
  FlowsEventFilterType,
  FunnelCountTypes,
  FunnelMeasureTypes,
  FunnelTrendStep,
  FunnelTrendType,
  FunnelType,
  GranularityEnum,
  PropertyAttribution,
  ViewMode,
} from '../features/reports/constants';
import { FlowQueryBuilder, FunnelQueryBuilder, FunnelStep, InsightQueryBuilder } from '../features/reports/types';
import { checkFunnelStepsContainsStep } from '../features/reports/utils/funnelDataUtils';
import { EventQueryBuilder } from '../lib/types';
import {
  breakdownsState,
  chartTypeState,
  compareState,
  dimensionsState,
  flowsEventFilterTypeState,
  flowsExcludeEventsState,
  flowsExpandedEventsListState,
  flowsIncludeEventsState,
  flowsRowCountState,
  formulasState,
  funnelConversionFunctionState,
  funnelConversionTimeWindowState,
  funnelStepState,
  funnelTrendStepState,
  funnelTrendTypeState,
  funnelTypeState,
  globalFiltersState,
  granularityState,
  propertyAttributionState,
  reportDescriptionState,
  reportNameState,
  runIdState,
  viewModeState,
} from './atoms';

export const getEmptyFunnelStepsState = (): FunnelStep[] => [
  {
    alias: `1`,
    first_time_filter: false,
    event: undefined,
    filter: [],
    step_label: '',
  },
  {
    alias: `2`,
    first_time_filter: false,
    event: undefined,
    filter: [],
    step_label: '',
  },
];

export const useResetAllAtomValues = () => {
  const [, setDimensionState] = useRecoilState(dimensionsState);
  const [, setBreakdownsState] = useRecoilState(breakdownsState);
  const [, setGlobalFiltersState] = useRecoilState(globalFiltersState);
  const [, setFormulasState] = useRecoilState(formulasState);
  const [, setGranularityState] = useRecoilState(granularityState);
  const [, setChartTypeState] = useRecoilState(chartTypeState);
  const [, setDateRangeState] = useRecoilState(dateRangeState);
  const [, setCompareState] = useRecoilState(compareState);
  const [, setReportNameState] = useRecoilState(reportNameState);
  const [, setReportDescriptionState] = useRecoilState(reportDescriptionState);
  const [, setRunIdState] = useRecoilState(runIdState);
  const [, setFunnelConversionWindow] = useRecoilState(funnelConversionTimeWindowState);
  const [, setFunnelConversionFunction] = useRecoilState(funnelConversionFunctionState);
  const [, setFunnelSteps] = useRecoilState(funnelStepState);
  const [, setFunnelType] = useRecoilState(funnelTypeState);
  const [, setFunnelTrendType] = useRecoilState(funnelTrendTypeState);
  const [, setFunnelTrendStep] = useRecoilState(funnelTrendStepState);
  const [, setViewMode] = useRecoilState(viewModeState);

  // flows specific atoms
  const resetFlowsRowCount = useResetRecoilState(flowsRowCountState);
  const resetFlowsSelectedtEventFilterType = useResetRecoilState(flowsEventFilterTypeState);
  const resetFlowsEventsToExclude = useResetRecoilState(flowsExcludeEventsState);
  const resetFlowsEventsToInclude = useResetRecoilState(flowsIncludeEventsState);
  const resetFlowsExapandedEvents = useResetRecoilState(flowsExpandedEventsListState);

  // insights specific atoms
  const resetInsightReportRow = useResetRecoilState(insightReportRowState);

  return () => {
    setDimensionState([]);
    setBreakdownsState([]);
    setGlobalFiltersState([]);
    setFormulasState([]);
    setGranularityState(GranularityEnum.DAY);
    setChartTypeState(ChartType.LINE);
    setDateRangeState(DateRangeEnum.SEVEN_DAYS);
    setCompareState([]);
    setReportNameState('');
    setReportDescriptionState('');
    setRunIdState(undefined);
    setFunnelConversionWindow({ number: 14, unit: PluralSupportedTimeUnits.DAYS });
    setFunnelConversionFunction(FunnelCountTypes.TOTALS);
    setFunnelSteps(getEmptyFunnelStepsState());
    setFunnelType(FunnelType.STEPS);
    setFunnelTrendType(FunnelTrendType.CONVERSION);
    setFunnelTrendStep(FunnelTrendStep.ALL_STEPS);
    setViewMode(ViewMode.LINEAR);

    // insights specific atoms
    resetInsightReportRow();

    // flows reset
    resetFlowsRowCount();
    resetFlowsSelectedtEventFilterType();
    resetFlowsEventsToExclude();
    resetFlowsEventsToInclude();
    resetFlowsExapandedEvents();
  };
};

export const useSetAllInsightAtomValues = () => {
  const [, setReportName] = useRecoilState(reportNameState);
  const [, setReportDescription] = useRecoilState(reportDescriptionState);
  const [, setDimensionState] = useRecoilState(dimensionsState);
  const [, setBreakdownsState] = useRecoilState(breakdownsState);
  const [, setGlobalFiltersState] = useRecoilState(globalFiltersState);
  const [, setFormulasState] = useRecoilState(formulasState);
  const [, setGranularityState] = useRecoilState(granularityState);
  const [, setChartTypeState] = useRecoilState(chartTypeState);
  const [, setDateRangeState] = useRecoilState(dateRangeState);
  const [, setCompareState] = useRecoilState(compareState);
  const [, setCustomDateRange] = useRecoilState(customDateRangeState);
  const [, setViewModeState] = useRecoilState(viewModeState);
  return (data: InsightQueryBuilder) => {
    setReportName(data.insightTitle);
    setReportDescription(data.insightDescription);
    setDimensionState(data.dimensions);
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setFormulasState(data.formulas || []);
    setGranularityState(data.granularity);
    setChartTypeState(data.chartType);
    setViewModeState(data.viewMode);
    setDateRangeState(data.dateRange);
    setCompareState(data.compare || []);
    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
  };
};

export const useGetAllInsightAtomValues = (): InsightQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsState);
  const breakdowns = useRecoilValue(breakdownsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const formulas = useRecoilValue(formulasState);
  const granularity = useRecoilValue(granularityState);
  const chartType = useRecoilValue(chartTypeState);
  const dateRange = useRecoilValue(dateRangeState);
  const compare = useRecoilValue(compareState);
  const insightTitle = useRecoilValue(reportNameState);
  const insightDescription = useRecoilValue(reportDescriptionState);
  const viewMode = useRecoilValue(viewModeState);

  return {
    insightTitle,
    insightDescription,
    dimensions,
    breakdowns,
    'global-filters': globalFilters,
    formulas,
    granularity,
    chartType,
    dateRange,
    viewMode,
    compare,
  };
};

export const useGetQueryBuilderDataForInsight = (): InsightQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsState);
  const breakdowns = useRecoilValue(breakdownsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const formulas = useRecoilValue(formulasState);
  const granularity = useRecoilValue(granularityState);
  const chartType = useRecoilValue(chartTypeState);
  const viewMode = useRecoilValue(viewModeState);
  const dateRange = useRecoilValue(dateRangeState);
  const insightTitle = useRecoilValue(reportNameState);
  const insightDescription = useRecoilValue(reportDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const compare = useRecoilValue(compareState);

  return {
    insightTitle,
    insightDescription,
    dimensions,
    breakdowns,
    'global-filters': globalFilters,
    formulas,
    granularity,
    chartType,
    dateRange,
    viewMode,
    customDateRange,
    sinceDateRange: customDateRange,
    compare,
  };
};

export const useSetAllFunnelAtomValues = () => {
  const [, setFunnelSteps] = useRecoilState(funnelStepState);
  const [, setBreakdownsState] = useRecoilState(breakdownsState);
  const [, setGlobalFiltersState] = useRecoilState(globalFiltersState);
  const [, setDateRangeState] = useRecoilState(dateRangeState);
  const [, setReportName] = useRecoilState(reportNameState);
  const [, setReportDescription] = useRecoilState(reportDescriptionState);
  const [, setCustomDateRange] = useRecoilState(customDateRangeState);
  const [, setCompareState] = useRecoilState(compareState);
  const [, setFunnelConversionWindow] = useRecoilState(funnelConversionTimeWindowState);
  const [, setFunnelConversionFunction] = useRecoilState(funnelConversionFunctionState);
  const [, setFunnelType] = useRecoilState(funnelTypeState);
  const [, setFunnelTrendType] = useRecoilState(funnelTrendTypeState);
  const [, setFunnelTrendStep] = useRecoilState(funnelTrendStepState);
  const [, setViewMode] = useRecoilState(viewModeState);
  const [, setGranularityState] = useRecoilState(granularityState);
  const [, setPropertyAttributionStatte] = useRecoilState(propertyAttributionState);

  return (data: FunnelQueryBuilder) => {
    setFunnelSteps(data.steps || getEmptyFunnelStepsState());
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    setReportName(data.funnelTitle);
    setReportDescription(data.funnelDescription);
    setCompareState(data.compare || []);
    setFunnelConversionWindow(
      data.measure_time_window || {
        number: 14,
        unit: PluralSupportedTimeUnits.DAYS,
      },
    );
    setFunnelConversionFunction(data.function || FunnelCountTypes.TOTALS);
    setFunnelType(data.funnelType || FunnelType.STEPS);
    setPropertyAttributionStatte(data.propertyAttribution || PropertyAttribution.LAST_TOUCH);

    if (data.funnelTrendType) {
      setFunnelTrendType(data.funnelTrendType);
    }
    if (data.funnelTrendStep) {
      setFunnelTrendStep(data.funnelTrendStep);
    }
    if (data.viewMode) {
      setViewMode(data.viewMode);
    }
    if (data.granularity) {
      setGranularityState(data.granularity);
    }

    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
  };
};

export const useGetQueryBuilderDataForFunnel = (): FunnelQueryBuilder => {
  const steps = useRecoilValue(funnelStepState);
  const breakdowns = useRecoilValue(breakdownsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const dateRange = useRecoilValue(dateRangeState);
  const funnelTitle = useRecoilValue(reportNameState);
  const funnelDescription = useRecoilValue(reportDescriptionState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const compare = useRecoilValue(compareState);
  const funnelConversionFunction = useRecoilValue(funnelConversionFunctionState);
  const funnelConversionWindow = useRecoilValue(funnelConversionTimeWindowState);
  const granularity = useRecoilValue(granularityState);
  const funnelType = useRecoilValue(funnelTypeState);
  const funnelTrendType = useRecoilValue(funnelTrendTypeState);
  const funnelTrendStep = useRecoilValue(funnelTrendStepState);
  const viewMode = useRecoilValue(viewModeState);
  const propertyAttribution = useRecoilValue(propertyAttributionState);

  return {
    funnelTitle,
    funnelDescription,
    steps: steps.map((dimension) => {
      if (!dimension.compare)
        return {
          ...dimension,
          step_label: checkFunnelStepsContainsStep(dimension.step_label, steps)
            ? `${dimension.alias} ${dimension.step_label}`
            : dimension.step_label ?? '',
        };

      return {
        ...dimension,
        compare: dimension.compare.map((compareStep) => ({
          ...compareStep,
          step_label: checkFunnelStepsContainsStep(compareStep.step_label, steps)
            ? `${compareStep.alias} ${compareStep.step_label}`
            : compareStep.step_label ?? '',
        })),
      };
    }),
    breakdowns,
    'global-filters': globalFilters,
    dateRange,
    viewMode,
    customDateRange,
    sinceDateRange: customDateRange,
    compare,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    granularity,
    measured_as: FunnelMeasureTypes.CONVERSION,
    funnelType,
    funnelTrendType,
    funnelTrendStep,
    propertyAttribution,
  };
};

export const useGetAllFunnelAtomValues = (): FunnelQueryBuilder => {
  const steps = useRecoilValue(funnelStepState);
  const breakdowns = useRecoilValue(breakdownsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const granularity = useRecoilValue(granularityState);
  const dateRange = useRecoilValue(dateRangeState);
  const compare = useRecoilValue(compareState);
  const funnelTitle = useRecoilValue(reportNameState);
  const funnelDescription = useRecoilValue(reportDescriptionState);
  const funnelConversionFunction = useRecoilValue(funnelConversionFunctionState);
  const funnelConversionWindow = useRecoilValue(funnelConversionTimeWindowState);
  const funnelType = useRecoilValue(funnelTypeState);
  const funnelTrendType = useRecoilValue(funnelTrendTypeState);
  const funnelTrendStep = useRecoilValue(funnelTrendStepState);
  const viewMode = useRecoilValue(viewModeState);
  const propertyAttribution = useRecoilValue(propertyAttributionState);

  return {
    funnelTitle,
    funnelDescription,
    steps,
    breakdowns,
    'global-filters': globalFilters,
    granularity,
    dateRange,
    viewMode,
    compare,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    measured_as: FunnelMeasureTypes.CONVERSION,
    funnelType,
    funnelTrendType,
    funnelTrendStep,
    propertyAttribution,
  };
};

export const useSetAllEventAtomValues = () => {
  const [, setDimensionState] = useRecoilState(dimensionsState);
  const [, setGlobalFiltersState] = useRecoilState(globalFiltersState);
  const [, setDateRangeState] = useRecoilState(dateRangeState);
  const [, setCustomDateRange] = useRecoilState(customDateRangeState);
  const [, setselectedColumnList] = useRecoilState(eventTableColumnListState);
  const [, setEventPropertyList] = useRecoilState(eventPropertyListState);
  const [, setGranularityState] = useRecoilState(granularityState);
  return (data: EventQueryBuilder) => {
    setDimensionState(data.dimensions);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    setselectedColumnList(data.selectedColumnList || []);
    setEventPropertyList(data.eventPropertyList || []);
    if (data.granularity) {
      setGranularityState(data.granularity);
    }
    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }
  };
};

export const useGetAllEventAtomValues = (): EventQueryBuilder => {
  const dimensions = useRecoilValue(dimensionsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const dateRange = useRecoilValue(dateRangeState);
  const selectedColumnList = useRecoilValue(eventTableColumnListState);
  const eventPropertyList = useRecoilValue(eventPropertyListState);
  const granularity = useRecoilValue(granularityState);

  return {
    dimensions,
    'global-filters': globalFilters,
    dateRange,
    selectedColumnList,
    eventPropertyList,
    granularity,
  };
};

// ReportType.FLOW helpers
export const useGetQueryBuilderDataForFlow = (): FlowQueryBuilder => {
  const flowTitle = useRecoilValue(reportNameState);
  const flowDescription = useRecoilValue(reportDescriptionState);
  const dimensions = useRecoilValue(dimensionsState);
  const globalFilters = useRecoilValue(globalFiltersState);
  const dateRange = useRecoilValue(dateRangeState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const funnelConversionFunction = useRecoilValue(funnelConversionFunctionState);
  const funnelConversionWindow = useRecoilValue(funnelConversionTimeWindowState);
  const granularity = useRecoilValue(granularityState);
  const flowsRowCount = useRecoilValue(flowsRowCountState);
  const flowsEventFilterType = useRecoilValue(flowsEventFilterTypeState);
  const flowsIncludeEvents = useRecoilValue(flowsIncludeEventsState);
  const flowsExcludeEvents = useRecoilValue(flowsExcludeEventsState);
  const flowsExpandedEvents = useRecoilValue(flowsExpandedEventsListState);

  return {
    flowTitle,
    flowDescription,
    flowsRowCount,
    flowsExpandedEvents,
    flowsEventFilterType,
    flowsIncludeEvents,
    flowsExcludeEvents,
    steps: dimensions,
    'global-filters': globalFilters,
    dateRange,
    customDateRange,
    sinceDateRange: customDateRange,
    function: funnelConversionFunction,
    measure_time_window: funnelConversionWindow,
    granularity,
  };
};

export const useSetAllFlowAtomValues = () => {
  const [, setDimensionState] = useRecoilState(dimensionsState);
  const [, setBreakdownsState] = useRecoilState(breakdownsState);
  const [, setGlobalFiltersState] = useRecoilState(globalFiltersState);
  const [, setDateRangeState] = useRecoilState(dateRangeState);
  const [, setReportName] = useRecoilState(reportNameState);
  const [, setReportDescription] = useRecoilState(reportDescriptionState);
  const [, setCustomDateRange] = useRecoilState(customDateRangeState);
  const [, setCompareState] = useRecoilState(compareState);
  const [, setFunnelConversionWindow] = useRecoilState(funnelConversionTimeWindowState);
  const [, setFunnelConversionFunction] = useRecoilState(funnelConversionFunctionState);
  const [, setGranularityState] = useRecoilState(granularityState);

  const [, setFlowsRowCount] = useRecoilState(flowsRowCountState);
  const [, setFlowsEventFilterType] = useRecoilState(flowsEventFilterTypeState);
  const [, setFlowsIncludeEvents] = useRecoilState(flowsIncludeEventsState);
  const [, setFlowsExcludeEvents] = useRecoilState(flowsExcludeEventsState);
  const [, setFlowsExpandedEvents] = useRecoilState(flowsExpandedEventsListState);
  const resetFlowsSelectedtEventFilterType = useResetRecoilState(flowsEventFilterTypeState);

  return (data: FlowQueryBuilder) => {
    setDimensionState(data.steps || []);
    setBreakdownsState(data.breakdowns || []);
    setGlobalFiltersState(data['global-filters'] || []);
    setDateRangeState(data.dateRange);
    setReportName(data.flowTitle);
    setReportDescription(data.flowDescription);
    setCompareState(data.compare || []);
    setFunnelConversionWindow(
      data.measure_time_window || {
        number: 14,
        unit: PluralSupportedTimeUnits.DAYS,
      },
    );
    setFunnelConversionFunction(data.function || FunnelCountTypes.TOTALS);

    if (data.granularity) {
      setGranularityState(data.granularity);
    }

    if (data.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(data.customDateRange)) {
      setCustomDateRange(data.customDateRange!);
    }
    if (data.dateRange === DateRangeEnum.SINCE && isLengthyArray(data.sinceDateRange)) {
      setCustomDateRange(data.sinceDateRange!);
    }

    setFlowsRowCount(data.flowsRowCount);
    setFlowsIncludeEvents(data.flowsIncludeEvents ?? []);
    setFlowsExcludeEvents(data.flowsExcludeEvents ?? []);
    setFlowsExpandedEvents(data.flowsExpandedEvents ?? []);
    if (isLengthyArray(data.flowsIncludeEvents)) {
      setFlowsEventFilterType(FlowsEventFilterType.INCLUDE_EVENTS);
    } else if (isLengthyArray(data.flowsExcludeEvents)) {
      setFlowsEventFilterType(FlowsEventFilterType.EXCLUDE_EVENTS);
    } else {
      resetFlowsSelectedtEventFilterType();
    }
  };
};

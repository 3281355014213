import { useTheme } from '@src/client/v2/ui-library/theme-provider';
import { SVGProps } from 'react';

export function DeleteIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.128}
        d="M17.389 6.79v-.851c0-1.192 0-1.788-.232-2.243-.204-.4-.53-.726-.93-.93-.455-.232-1.05-.232-2.243-.232h-1.702c-1.192 0-1.788 0-2.243.232-.4.204-.726.53-.93.93-.232.455-.232 1.051-.232 2.243v.851m-5.32 0H22.71m-2.128 0v11.916c0 1.788 0 2.682-.348 3.364a3.192 3.192 0 0 1-1.395 1.395c-.683.348-1.576.348-3.364.348h-4.681c-1.788 0-2.682 0-3.365-.348a3.192 3.192 0 0 1-1.395-1.395c-.347-.682-.347-1.576-.347-3.364V6.79"
      />
    </svg>
  );
}

export function ExternalLinkIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.128}
        d="M22.587 10.046V3.662m0 0h-6.384m6.384 0-9.576 9.576m-2.128-9.576h-2.34c-1.788 0-2.682 0-3.365.348-.6.306-1.089.794-1.395 1.395-.347.683-.347 1.576-.347 3.364v8.937c0 1.788 0 2.682.347 3.364.306.601.795 1.09 1.395 1.395.683.348 1.577.348 3.365.348h8.937c1.787 0 2.681 0 3.364-.348a3.192 3.192 0 0 0 1.395-1.395c.348-.682.348-1.576.348-3.364v-2.34"
      />
    </svg>
  );
}

export function VerticalDotsIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={27} height={26} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.128}
        d="M13.57 14.238a1.064 1.064 0 1 0 0-2.128 1.064 1.064 0 0 0 0 2.128ZM13.57 6.79a1.064 1.064 0 1 0 0-2.128 1.064 1.064 0 0 0 0 2.128ZM13.57 21.685a1.064 1.064 0 1 0 0-2.128 1.064 1.064 0 0 0 0 2.128Z"
      />
    </svg>
  );
}

export function EditIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke={stroke ?? theme === 'light' ? '#475467' : '#D0D5DD'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m13.5 7.5-3-3M1.875 16.125l2.538-.282c.31-.034.465-.052.61-.098.129-.042.251-.1.364-.175.127-.084.238-.194.458-.415L15.75 5.25a2.121 2.121 0 0 0-3-3l-9.905 9.905c-.22.22-.33.33-.415.458a1.5 1.5 0 0 0-.174.364c-.047.145-.065.3-.099.61l-.282 2.538Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SingleUserIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke ?? theme === 'light' ? '#475467' : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M16.667 17.5c0-1.163 0-1.745-.144-2.218a3.333 3.333 0 0 0-2.222-2.222c-.473-.143-1.055-.143-2.218-.143H7.917c-1.163 0-1.745 0-2.218.143a3.333 3.333 0 0 0-2.222 2.222c-.144.473-.144 1.055-.144 2.218M13.75 6.25a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      />
    </svg>
  );
}

export function SingleUserAddIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg width={20} height={20} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 16H7.5C6.10444 16 5.40665 16 4.83886 16.1722C3.56045 16.56 2.56004 17.5605 2.17224 18.8389C2 19.4067 2 20.1044 2 21.5M19 21.5V15.5M16 18.5H22M14.5 8C14.5 10.4853 12.4853 12.5 10 12.5C7.51472 12.5 5.5 10.4853 5.5 8C5.5 5.51472 7.51472 3.5 10 3.5C12.4853 3.5 14.5 5.51472 14.5 8Z"
        stroke={stroke ?? theme === 'light' ? '#475467' : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DashboardCardErrorSvg({ fill, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
      <path
        fill={fill ?? '#D0D5DD'}
        d="M10.154 6.326a4 4 0 0 0-4 4v81.348a4 4 0 0 0 4 4h45.75a4 4 0 0 0 3.392-1.88L75.59 67.725a1.889 1.889 0 0 1-.014-.213V33.904a4 4 0 0 0-4-4h-19.75A1.827 1.827 0 0 1 50 28.076v-17.75a4 4 0 0 0-4-4H10.154ZM57.036 9.87c-1.272-1.216-3.382-.315-3.382 1.446V24.25a2 2 0 0 0 2 2h13.53c1.803 0 2.686-2.2 1.382-3.446L57.036 9.87Zm-39.92 10.9c1.008 0 1.825.817 1.825 1.826V57.85l7.748-9.04a1.824 1.824 0 0 1 2.68-.101l8.8 8.799 4.601-15.34a1.826 1.826 0 0 1 3.152-.645l7.348 8.817 7.884-15.772a1.828 1.828 0 0 1 3.268 1.633l-9.135 18.27a1.829 1.829 0 0 1-3.037.353l-6.959-8.351-4.502 15.013a1.828 1.828 0 0 1-3.043.768l-9.566-9.566-9.239 10.777v4.805h45.674a1.825 1.825 0 1 1 0 3.652H18.941v3.654a1.827 1.827 0 1 1-3.652 0v-3.654h-3.654a1.826 1.826 0 1 1 0-3.652h3.654V22.596c0-1.01.817-1.826 1.826-1.826ZM79.1 74.43a2 2 0 0 0-3.392 0L64.343 92.614c-.833 1.332.125 3.06 1.696 3.06h22.73c1.57 0 2.528-1.728 1.696-3.06L79.1 74.43Zm-1.696 2.974c1.01 0 1.827.818 1.826 1.826v7.309a1.827 1.827 0 0 1-3.654 0v-7.31c0-1.008.82-1.825 1.828-1.825Zm.092 12.79a1.835 1.835 0 0 1 1.428.812c.064.1.12.205.166.314.046.11.08.223.103.342.023.119.038.239.037.358a1.869 1.869 0 0 1-.535 1.29 1.856 1.856 0 0 1-1.29.536c-.12 0-.239-.015-.358-.037a1.995 1.995 0 0 1-.656-.27 1.972 1.972 0 0 1-.28-.228 2.263 2.263 0 0 1-.228-.278 1.995 1.995 0 0 1-.27-.658 1.954 1.954 0 0 1-.037-.355c0-.48.197-.951.535-1.293a1.85 1.85 0 0 1 .28-.23c.1-.063.205-.117.314-.163.11-.045.223-.083.342-.105.148-.032.3-.041.45-.034Z"
      />
    </svg>
  );
}

export function RefereshIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke ?? '#155EEF'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M11.667 1.667s.707.101 3.636 3.03a7.5 7.5 0 0 1-3.636 12.617m0-15.647h5m-5 0v5M8.333 18.334s-.707-.102-3.636-3.03A7.5 7.5 0 0 1 8.333 2.686m0 15.646h-5m5 0v-5"
      />
    </svg>
  );
}

export function DropdownIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
      <path
        stroke={stroke ?? '#475467'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M1.667 4.167l5 5 5-5"
      />
    </svg>
  );
}

export function SaveIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M5.833 2.5v2.833c0 .467 0 .7.091.879.08.156.208.284.364.364.179.09.412.09.879.09h5.666c.467 0 .7 0 .879-.09a.833.833 0 0 0 .364-.364c.09-.179.09-.412.09-.879v-2m0 14.167v-5.333c0-.467 0-.7-.09-.879a.833.833 0 0 0-.364-.364c-.179-.09-.412-.09-.879-.09H7.167c-.467 0-.7 0-.879.09a.833.833 0 0 0-.364.364c-.09.179-.09.412-.09.879V17.5M17.5 7.771V13.5c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 15.6 2.5 14.9 2.5 13.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C4.4 2.5 5.1 2.5 6.5 2.5h5.729c.407 0 .611 0 .803.046.17.04.333.108.482.2.168.103.312.247.6.535l2.605 2.605c.288.288.432.432.535.6.092.15.16.312.2.482.046.192.046.396.046.803Z"
      />
    </svg>
  );
}

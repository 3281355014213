import { ReactNode } from 'react';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from './NavbarPrimitives';

export interface NavbarItem {
  key: string;
  item: ReactNode;
  child?: ReactNode;
}
export interface NavigationMenuProps {
  navbarItems: NavbarItem[];
}
export function NavMenu({ navbarItems }: NavigationMenuProps) {
  return (
    <NavigationMenu className="-ml-20">
      <NavigationMenuList>
        {navbarItems.map((navItem) => (
          <NavigationMenuItem key={navItem.key}>
            {navItem.child ? (
              <>
                <NavigationMenuTrigger>{navItem.item}</NavigationMenuTrigger>
                <NavigationMenuContent>{navItem.child}</NavigationMenuContent>
              </>
            ) : (
              navItem.item
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

import * as React from 'react';

import { cn } from '../utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => (
  <input
    type={type}
    className={cn(
      'flex h-9 w-full rounded-xl border border-border shadow-xs bg-transparent px-3 py-2 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-light focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    ref={ref}
    {...props}
  />
));
Input.displayName = 'Input';

const DebouncedInput = React.forwardRef<
  HTMLInputElement,
  InputProps & { debounce?: number; onChange: (val: string | number | readonly string[] | undefined) => void }
>(({ className, type, value: initialValue, onChange, debounce = 300, ...props }, ref) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange) {
        onChange(value);
      }
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <input
      type={type}
      className={cn(
        'flex h-11 w-full rounded-xl border border-border shadow-xs bg-transparent px-3 py-2 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-secondary placeholder:text-sm focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      ref={ref}
      {...props}
    />
  );
});
DebouncedInput.displayName = 'DebouncedInput';

export { DebouncedInput, Input };

import { useTheme } from '@src/client/v2/ui-library/theme-provider';
import { SVGProps } from 'react';

export function FiltersIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3.386 6.167C2.63 5.322 2.25 4.899 2.237 4.54a1 1 0 0 1 .363-.811c.277-.229.844-.229 1.978-.229h14.843c1.134 0 1.702 0 1.979.229a1 1 0 0 1 .362.81c-.014.36-.392.783-1.148 1.628l-5.707 6.377c-.15.169-.226.253-.28.35a1 1 0 0 0-.103.27c-.024.108-.024.221-.024.447v5.347c0 .196 0 .294-.032.378a.5.5 0 0 1-.132.196c-.067.06-.158.096-.34.17l-3.4 1.36c-.367.146-.55.22-.698.189a.5.5 0 0 1-.315-.213c-.083-.126-.083-.324-.083-.72v-6.707c0-.226 0-.339-.024-.446a1 1 0 0 0-.104-.272c-.054-.096-.13-.18-.28-.349L3.386 6.167Z"
      />
    </svg>
  );
}

export function CloseIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
        d="m12 4-8 8m0-8 8 8"
      />
    </svg>
  );
}

export function DimensionIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21.5H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.74 3 20.46 3 19.9V3.5m17 5-3.919 4.183c-.148.158-.223.237-.312.278a.5.5 0 0 1-.253.044c-.098-.01-.194-.06-.387-.16l-3.258-1.69c-.193-.1-.289-.15-.387-.16a.5.5 0 0 0-.253.044c-.09.04-.164.12-.312.278L7 15.5"
      />
    </svg>
  );
}

export function InlineFiltersIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
        d="M2.257 3.778c-.504-.564-.756-.845-.766-1.085a.667.667 0 0 1 .242-.54C1.918 2 2.296 2 3.052 2h9.895c.757 0 1.135 0 1.32.153.16.132.25.332.241.54-.01.24-.261.521-.766 1.085L9.938 8.03c-.1.112-.15.168-.187.232a.667.667 0 0 0-.069.181c-.016.072-.016.147-.016.298v3.565c0 .13 0 .195-.02.252a.334.334 0 0 1-.089.13c-.044.04-.105.064-.226.113l-2.267.906c-.245.098-.367.147-.465.127a.334.334 0 0 1-.21-.142c-.056-.084-.056-.216-.056-.48V8.741c0-.15 0-.226-.016-.298a.667.667 0 0 0-.07-.18c-.035-.065-.085-.121-.186-.233L2.257 3.778Z"
      />
    </svg>
  );
}

export function SelectCheckIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M16.667 5 7.5 14.167 3.333 10"
      />
    </svg>
  );
}

export function EventDropdownIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M7.5 2.917v-1.25m-3.283 2.55-.884-.884m.884 7.5-.884.884m7.5-7.5.884-.884M2.917 7.5h-1.25m11.553 5.991-2.076 3.857c-.237.44-.356.66-.499.716a.417.417 0 0 1-.375-.037c-.13-.082-.203-.321-.35-.8L7.037 7.872c-.12-.39-.18-.586-.132-.718a.417.417 0 0 1 .248-.247c.132-.049.327.011.718.132l9.356 2.882c.479.147.718.22.8.35a.417.417 0 0 1 .037.376c-.056.142-.276.26-.717.498l-3.856 2.076a.88.88 0 0 0-.127.076.419.419 0 0 0-.068.069c-.023.028-.04.06-.076.126Z"
      />
    </svg>
  );
}

export function CohortDropdownIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M18.333 17.5v-1.667a3.335 3.335 0 0 0-2.5-3.228m-2.917-9.863a3.334 3.334 0 0 1 0 6.182m1.25 8.576c0-1.553 0-2.33-.253-2.942a3.334 3.334 0 0 0-1.804-1.804c-.613-.254-1.39-.254-2.943-.254h-2.5c-1.553 0-2.33 0-2.942.254a3.334 3.334 0 0 0-1.804 1.804c-.253.612-.253 1.389-.253 2.942M11.25 5.833a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z"
      />
    </svg>
  );
}

export function EventPropertyDropdownIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M17.5 7.917V6.5c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093C15.6 2.5 14.9 2.5 13.5 2.5h-7c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093C2.5 4.4 2.5 5.1 2.5 6.5v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092C4.4 17.5 5.1 17.5 6.5 17.5h1.417m6.572-2.74-1.416 2.628c-.231.43-.347.644-.488.7a.417.417 0 0 1-.372-.032c-.13-.08-.206-.312-.36-.775l-2.27-6.855c-.134-.405-.2-.608-.153-.743a.417.417 0 0 1 .253-.253c.135-.048.338.02.743.154l6.855 2.27c.463.153.695.23.775.359.069.112.08.25.032.372-.056.141-.27.257-.7.488l-2.629 1.415c-.065.036-.098.053-.126.076a.412.412 0 0 0-.069.069c-.023.028-.04.061-.075.126Z"
      />
    </svg>
  );
}

export function TripleDots({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
      <path
        fill={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        d="M9 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 4.5A.75.75 0 1 0 9 3a.75.75 0 0 0 0 1.5ZM9 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      />
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 4.5A.75.75 0 1 0 9 3a.75.75 0 0 0 0 1.5ZM9 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      />
    </svg>
  );
}

export function FirstTimeFilterIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke={theme === 'light' ? '#475467' : '#ffffff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.333}
          d="M9.667 12.667 11 14l3-3m.657-2.633a6.667 6.667 0 1 0-6.831 6.298M8 4v4l2.492 1.246"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={theme === 'light' ? '#ffffff' : '#475467'} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BreakdownIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={theme === 'light' ? '#475467' : '#ffffff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m9.75 21.25 1.473.818c.284.158.425.237.576.268.133.027.27.027.402 0 .15-.031.292-.11.576-.268l1.473-.818m-9-2.5-1.427-.793c-.3-.166-.45-.25-.558-.368a1 1 0 0 1-.215-.364C3 17.073 3 16.901 3 16.56V15m0-5V8.442c0-.343 0-.514.05-.667a1 1 0 0 1 .215-.364c.109-.119.258-.202.558-.368L5.25 6.25m4.5-2.5 1.473-.818c.284-.158.425-.237.576-.267a1 1 0 0 1 .402 0c.15.03.292.11.576.267l1.473.818m4.5 2.5 1.427.793c.3.166.45.25.558.368a1 1 0 0 1 .215.364c.05.153.05.324.05.667V10m0 5v1.559c0 .342 0 .514-.05.666a.999.999 0 0 1-.215.364c-.109.119-.258.202-.558.368l-1.427.793m-9-7.5L12 12.5m0 0 2.25-1.25M12 12.5V15M3 7.5l2.25 1.25m13.5 0L21 7.5M12 20v2.5"
      />
    </svg>
  );
}

export function FormulaIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={theme === 'light' ? '#475467' : '#ffffff'}
        d="M13.176 4C9.592 4 9.19 8.674 8.98 10H7.115l-.237 1.333h1.906c-1.06 7.35-1.513 7.854-2.035 7.854-.474-1.548-.819-2.044-1.537-2.044-1.007 0-1.212.97-1.212 1.337 0 .826.585 1.52 1.727 1.52 4.227 0 4.563-4.049 5.225-8.667h1.95l.24-1.333h-2.016c.212-1.917.44-4.69 1.064-5.188.104.32.484 2.045 1.539 2.045 1.134 0 1.229-1.235 1.229-1.38C14.957 5.034 14.743 4 13.176 4Zm2.45 8.667c-.583 0-1.325.413-1.65.584l-.119.58c.09-.039.515-.193.7-.193.646 0 .754.347 1.066 1.58.074.292.175.722.295 1.294-.134.222-.568.805-.622.857-.141-.06-.582-.238-.95-.238-.838 0-1.034.85-1.034 1.23 0 .339.2.972.795.972.843 0 1.084-.776 1.928-2.246.223 1.056.277 2.246 1.656 2.246.59 0 1.292-.334 1.649-.514l.12-.58s-.4.192-.699.192c-.868 0-.86-.74-1.372-2.943.14-.226.512-.79.632-.883.122.051.563.23.947.23.288 0 1.032-.216 1.032-1.215 0-.345-.176-.953-.795-.953-.98 0-1.48 1.569-1.937 2.252-.248-1.451-.51-2.252-1.641-2.252Z"
      />
    </svg>
  );
}

export function ConversionCriteriaIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={theme === 'light' ? '#475467' : '#ffffff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 20.5h3.4c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C16 15.94 16 14.26 16 10.9V4.5m0 0-5 5m5-5 5 5"
      />
    </svg>
  );
}

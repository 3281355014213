export enum GranularityEnum {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
}

export enum ChartType {
  LINE = 'Line',
  BAR = 'Bar',
  PIE = 'Pie',
  METRIC = 'Metric',
  TABLE = 'Table',
}

export enum DateRangeEnum {
  CUSTOM = 'custom',
  SINCE = 'since',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  SEVEN_DAYS = '7d',
  FOURTEEN_DAYS = '14d',
  THIRTY_DAYS = '30d',
  THREE_MONTH = '3m',
  SIX_MONTH = '6m',
  TWELVE_MONTH = '12m',
}

export enum CompareType {
  TIME_PERIOD = 'time_period',
}

export enum CompareTimePeriodSubTypes {
  PERVIOUS_DAY = 'previous_day',
  PERVIOUS_WEEK = 'previous_week',
  PERVIOUS_MONTH = 'previous_month',
  PREVIOUS_QUARTER = 'previous_quarter',
}
export const INITIAL_POLLING_INTERVAL = 1000;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export enum DimensionMathValues {
  TOTAL = 'total',
  UNIQUE = 'unique',
  SESSION = 'session',
  COUNT_USERS = 'count_users',
  AGGREGATE_PROPERTIES = 'aggregate_properties',
}

export enum DimensionMathCountUsers {
  DAU = 'dau',
  WAU = 'wau',
  MAU = 'mau',
}

export const FILTER_OPERATORS = [
  'equals',
  'not_equals',
  'contains',
  'not_contains',
  'greater_than_equal',
  'less_than_equal',
];

export const FILTER_CONECTORS = ['&', '||'];

export const AGGREGATE = 'aggregate';

export const PERCENTILE = 'percentile';

export const GRAPH_COLORS = [
  '6929c4',
  '1192e8',
  '005d5d',
  '9f1853',
  'fa4d56',
  '570408',
  '198038',
  '002d9c',
  'ee538b',
  'b28600',
  '009d9a',
  '012749',
  '8a3800',
  'a56eff',
];

export const MAX_GRAPH_COLORS = GRAPH_COLORS.length;

export const MAX_FUNNEL_STEP_GROUP = 10;

export enum FunnelCountTypes {
  UNIQUES = 'unique',
  TOTALS = 'total',
  SESSION = 'session',
}

export enum FunnelMeasureTypes {
  TRENDS = 'TRENDS',
  CONVERSION = 'conversion',
}

export type FunnelMetricsKeys = 'avg_time_from_prev_step' | 'percent_from_prev_step' | 'total';

export const FUNNEL_METRICS: Record<FunnelMetricsKeys, string> = {
  avg_time_from_prev_step: 'Average Time from previous step',
  // avg_time_from_first_step: 'Average Time From Start',
  percent_from_prev_step: 'Conversion from previous step',
  // percent_from_first_step: 'Conversion from first step',
  total: 'Count',
};

export const FUNNEL_PERCENTAGE_METRICS: string[] = ['percent_from_first_step', 'percent_from_prev_step'];
export const FUNNEL_TIME_METRICS: string[] = ['avg_time_from_prev_step', 'avg_time_from_first_step'];

export enum FunnelType {
  STEPS = 'steps',
  TRENDS = 'trends',
  FUNNEL_DROPOFF = 'funnel_dropoff',
}

export enum FunnelTrendType {
  CONVERSION = 'conversion',
  TIME_TO_CONVERT = 'time_to_convert',
  TOP_OF_FUNNEL = 'top_of_funnel',
  BOTTOM_OF_FUNNEL = 'bottom_of_funnel',
}

export enum ViewMode {
  LINEAR = 'linear',
  LOGARITHMIC = 'logarithmic',
  CUMULATIVE = 'cumulative',
}

export enum FunnelTrendStep {
  ALL_STEPS = 'all_steps',
}

export enum PropertyAttribution {
  LAST_TOUCH = 'last_touch',
  FIRST_TOUCH = 'first_touch',
  PER_ATTRIBUTE = 'per_attribute',
}

export enum ValueType {
  TIME,
  PERCENTAGE,
  NUMBER,
}

export enum FlowsEventFilterType {
  INCLUDE_EVENTS = 'INCLUDE_EVENTS',
  EXCLUDE_EVENTS = 'EXCLUDE_EVENTS',
}

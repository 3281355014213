/* eslint-disable array-callback-return,  @typescript-eslint/naming-convention */
import { DimensionType, GroupByObject } from '@src/client/lib/api/types/response';
import { convertDayJsDateTimeToIST, getTypeOfFilterPropertyValues, isLengthyArray } from '@src/client/lib/utils';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  AGGREGATE,
  CompareType,
  DateRangeEnum,
  DimensionMathCountUsers,
  DimensionMathValues,
  GranularityEnum,
  GRAPH_COLORS,
  PERCENTILE,
} from '../constants';
import {
  AggregatedProperties,
  Breakdown,
  ChartData,
  Dimension,
  Filter,
  FormattedFiltersConfig,
  FormattedFiltersForAPI,
  Formula,
  FunnelStep,
  LineChartData,
} from '../types';
import { checkFunnelStepHasEventCompare } from './funnelDataUtils';
import { DimensionMathAggregateOptions, DimensionMathOptions } from './uiHelpers';

dayjs.extend(utc);

export const isBreakdownApplied = (breakdowns: Breakdown[]): boolean => isLengthyArray(breakdowns);

export const isFormulaApplied = (formulas: Formula[]): boolean => isLengthyArray(formulas);

export const getBreakDownStrArray = (breakdowns: Breakdown[]) =>
  breakdowns.map((item) => (typeof item.property === 'string' ? item.property : `Cohort ${item.property.length}`));

export const getFilterFormObjFromReportResponse = (filters: FormattedFiltersForAPI): Filter[] => {
  const formattedValues = filters.config.map(
    ({ operator, property_name: property, property_values: values, property_map: map, resource_type }: any, index) => ({
      key: index,
      operator,
      property,
      values,
      map,
      resource_type,
    }),
  );
  return formattedValues;
};

const getDimensionMathCountUserList = () => [
  DimensionMathCountUsers.DAU.toString(),
  DimensionMathCountUsers.WAU.toString(),
  DimensionMathCountUsers.MAU.toString(),
];

const getDimensionMathAndAggregatedOnFormObj = (
  math: string,
  aggregateProperties: AggregatedProperties | undefined,
  uniqueOn: string | undefined,
): { math: string[]; aggregatedOn: string | null } => {
  if (math === AGGREGATE && aggregateProperties !== undefined) {
    const obj: { math: string[]; aggregatedOn: string | null } = { math: [], aggregatedOn: null };
    if (aggregateProperties.aggregate === PERCENTILE && aggregateProperties.percentile_value !== undefined) {
      const aggregatePropertyChild = DimensionMathAggregateOptions.children?.find(
        (c) =>
          aggregateProperties.percentile_value &&
          parseFloat(c.value) === parseFloat(aggregateProperties.percentile_value.toString()),
      );
      const mathValue = aggregatePropertyChild === undefined ? '0.5' : aggregatePropertyChild.value;
      obj.math = [DimensionMathValues.AGGREGATE_PROPERTIES, mathValue];
    } else {
      obj.math = [DimensionMathValues.AGGREGATE_PROPERTIES, aggregateProperties.aggregate];
    }
    if (aggregateProperties.column_name !== undefined) {
      obj.aggregatedOn = aggregateProperties.column_name;
    }
    if (uniqueOn !== undefined) {
      obj.aggregatedOn = uniqueOn;
    }
    return obj;
  }
  if (getDimensionMathCountUserList().includes(math)) {
    return { math: [DimensionMathValues.COUNT_USERS, math], aggregatedOn: null };
  }
  return { math: [math], aggregatedOn: null };
};

export const getGlobalDimensionFormObjFromInsightResponse = (dimensions: Dimension[]) => {
  const formattedValues: any[] = [];
  dimensions.map((it: any) => {
    const { alias, math, aggregate_properties, name, filters, first_time_filter, dimension_label } = it;
    const obj: any = { alias, name, first_time_filter };
    const mathAndAggregatedOnObj = getDimensionMathAndAggregatedOnFormObj(math, aggregate_properties, it['unique-on']);
    if (mathAndAggregatedOnObj.aggregatedOn) {
      obj['aggregate-on'] = mathAndAggregatedOnObj.aggregatedOn;
    }
    if (mathAndAggregatedOnObj.math) {
      obj.math = mathAndAggregatedOnObj.math;
    }
    if (filters && filters.mapping) {
      obj['filter-mapping'] = filters.mapping;
    }
    if (dimension_label && dimension_label.length > 0) {
      obj.dimension_label = dimension_label;
    }
    obj.filter =
      filters && filters.config && filters.config.length > 0 ? getFilterFormObjFromReportResponse(filters) : [];
    formattedValues.push(obj);
  });
  return formattedValues;
};

export const getCustomDateRangeFormObjReportResponse = (timestampProps: any): [dayjs.Dayjs, dayjs.Dayjs] => {
  const from = timestampProps.from_timestamp;
  const to = timestampProps.to_timestamp;
  const customDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(from), dayjs(to)];
  return customDateRange;
};

export const getCompareFormObjFromReportResponse = (compare: any) => [compare.type, compare.sub_type];

export const getSinceDateRangeFormObjReportResponse = (timestampProps: any): [dayjs.Dayjs, dayjs.Dayjs] => {
  const from = timestampProps.from_timestamp;
  const to = dayjs();
  const sinceDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(from), to];
  return sinceDateRange;
};

export const getFormObjectForGroupBy = (group_by: GroupByObject[]): { property: string | string[] }[] => {
  const tempGroupBy: { property: string | string[] }[] = [];
  group_by.forEach((grpBy: GroupByObject) => {
    if (typeof grpBy.property === 'string')
      tempGroupBy.push({ property: `${grpBy.property}|${grpBy.resource_type.toUpperCase()}` });
    else {
      tempGroupBy.push({
        property: grpBy.property.map((cohortId) => `${cohortId}|${grpBy.resource_type.toUpperCase()}`),
      });
    }
  });
  return tempGroupBy;
};

export const getFormattedGroupBy = (groupBy: Breakdown[]): GroupByObject[] => {
  const tempGroupBy: GroupByObject[] = [];
  groupBy.forEach((groupByObj) => {
    if (typeof groupByObj.property === 'string') {
      const splitArr = groupByObj.property.split('|');
      const prop = splitArr[0];
      const resType = isLengthyArray(splitArr) ? groupByObj.property?.split('|')[1] : 'EVENT';
      tempGroupBy.push({ property: prop, resource_type: resType as DimensionType });
    } else {
      const arr = groupByObj.property;
      const tempCohortsList = arr.map((cohortString: string) => {
        const splitArr = cohortString.split('|');
        const cohortId = splitArr[0];
        return cohortId;
      });
      tempGroupBy.push({ property: tempCohortsList, resource_type: DimensionType.COHORT });
    }
  });
  return tempGroupBy;
};

export const getLogicalOperatorInWords = (symbol: string): string => {
  if (symbol === '&') {
    return 'and';
  }
  if (symbol === '||') {
    return 'or';
  }
  return '&';
};

export const getFilterMapping = (filters: any[]): string => {
  let filterMapping = '';
  filters.map((item) => {
    if (parseInt(item.key, 10) === 1) {
      filterMapping = filterMapping.concat(item.key.toString()).concat(' ');
    }
    if (parseInt(item.key, 10) > 1) {
      filterMapping = filterMapping
        .concat(getLogicalOperatorInWords(item.map))
        .concat(' ')
        .concat(item.key.toString().concat(' '));
    }
  });
  return filterMapping.trimEnd();
};

const getDimensionMath = (math: string[]) => {
  if (math && math.length === 1) {
    return math[0];
  }
  const selectedOption = DimensionMathOptions.find((d) => d.value === math[0]);
  const isAggregatedProprty = selectedOption && selectedOption.value === DimensionMathValues.AGGREGATE_PROPERTIES;
  return isAggregatedProprty ? AGGREGATE : math[1];
};

const getAggregatedProperties = (mathAsPerForm: string[], aggregatedOn: string): AggregatedProperties | undefined => {
  const mathAsFormatted = getDimensionMath(mathAsPerForm);
  if (mathAsFormatted === AGGREGATE) {
    const aggregatedPropertyOptions = DimensionMathOptions.find(
      (d) => d.value === DimensionMathValues.AGGREGATE_PROPERTIES,
    );
    if (aggregatedPropertyOptions !== undefined && aggregatedPropertyOptions.children) {
      const selectedOptionChild = aggregatedPropertyOptions.children.find((c) => c.value === mathAsPerForm[1]);
      const isPercentile = selectedOptionChild && parseFloat(selectedOptionChild.value);
      const aggregatedProperties: AggregatedProperties = {
        aggregate: isPercentile ? PERCENTILE : mathAsPerForm[1],
        column_name: aggregatedOn,
      };
      if (isPercentile) {
        aggregatedProperties.percentile_value = parseFloat(mathAsPerForm[1]);
      }
      return aggregatedProperties;
    }
    return undefined;
  }
  return undefined;
};

export const getFormattedDimensionFilters = (f: Filter): FormattedFiltersConfig | undefined => {
  const configObj: any = {};
  if (!isLengthyArray(f.values) && !(typeof f.values === 'string' && f.values.length > 0)) return undefined;

  if (f.key) configObj.key = f.key;
  configObj.resource_type = f.resource_type;
  if (f.property) configObj.property_name = f.property;
  if (f.operator) configObj.operator = f.operator;
  if (f.values) configObj.property_values = f.values;
  if (f.values) configObj.property_value_dt = getTypeOfFilterPropertyValues(f.values);
  if (f.map) configObj.property_map = f.map;
  return configObj;
};

export const getFormattedDimensions = (dimensions: Dimension[]) => {
  const formattedDimensions: Dimension[] = [];
  dimensions.map((item: any) => {
    const obj: any = {};
    if (item.alias) obj.alias = item.alias;
    if (item.math) obj.math = getDimensionMath(item.math);
    if (item.name) obj.name = item.name;
    if (item.first_time_filter) obj.first_time_filter = item.first_time_filter;
    if (item['aggregate-on']) {
      const aggregatedProperties = getAggregatedProperties(item.math, item['aggregate-on']);
      if (aggregatedProperties !== undefined) {
        obj.aggregate_properties = aggregatedProperties;
      }
    }
    if (item.dimension_label && item.dimension_label.length > 0) {
      obj.dimension_label = item.dimension_label;
    }
    const filterList = item.filter;
    if (filterList !== undefined && filterList.length > 0) {
      const filterObj: any = {};
      const filterConfig: any[] = [];

      filterList.forEach((f: any) => {
        if (!isLengthyArray(f.values) && !(typeof f.values === 'string' && f.values.length > 0)) return;
        const configObj: any = getFormattedDimensionFilters(f);
        filterConfig.push(configObj);
      });
      const filterMappingStr = getFilterMapping(filterList);
      if (filterMappingStr !== '') filterObj.mapping = filterMappingStr;
      filterObj.config = filterConfig;
      obj.filters = filterObj;
    }
    formattedDimensions.push(obj);
  });
  return formattedDimensions;
};

export const getFormattedGlobalFilters = (filters: Filter[]): FormattedFiltersForAPI => {
  const globalFilters: any = {};
  const globalFiltersConfig: any[] = [];

  filters.forEach((gf: any) => {
    if (!isLengthyArray(gf.values) && !(typeof gf.values === 'string' && gf.values.length > 0)) return;

    const globalFiltersConfigObj: any = {};
    if (gf.resource_type) {
      if (gf.resource_type === DimensionType.PROPERTY) {
        globalFiltersConfigObj.resource_type = DimensionType.EVENT;
      } else {
        globalFiltersConfigObj.resource_type = gf.resource_type;
      }
    } else globalFiltersConfigObj.resource_type = 'event';
    // globalFiltersConfigObj['resource_type'] = 'event'
    if (gf.key) globalFiltersConfigObj.key = gf.key;
    if (gf.property) globalFiltersConfigObj.property_name = gf.property;
    if (gf.operator) globalFiltersConfigObj.operator = gf.operator;
    if (gf.values) globalFiltersConfigObj.property_values = gf.values;
    if (gf.values) globalFiltersConfigObj.property_value_dt = getTypeOfFilterPropertyValues(gf.values);
    if (gf.map) globalFiltersConfigObj.property_map = gf.map;

    globalFiltersConfig.push(globalFiltersConfigObj);
  });

  globalFilters.config = globalFiltersConfig;
  const globalFilterMappingStr = getFilterMapping(filters);
  if (globalFilterMappingStr !== '') globalFilters.mapping = globalFilterMappingStr;
  return globalFilters;
};

export const getFormattedChartProps = (viewMode: any, chartType: any) => {
  const chartProps: any = {};
  if (viewMode !== undefined) chartProps.view_mode = viewMode;
  if (chartType !== undefined) chartProps.chart_type = chartType;
  return chartProps;
};

const getDatesFromCustomRange = (value: [Dayjs, Dayjs] | []) => {
  const firstValue = typeof value[0] === 'string' ? dayjs(value[0]) : value[0];
  const secondValue = typeof value[1] === 'string' ? dayjs(value[1]) : value[1];
  const newObj: { from_timestamp?: string; to_timestamp?: string } = {
    from_timestamp: firstValue ? convertDayJsDateTimeToIST(firstValue) : '',
    to_timestamp: secondValue ? convertDayJsDateTimeToIST(secondValue, true) : '',
  };
  return newObj;
};

export const getFormattedTimestampProps = (
  granularity: any,
  dateRange: any,
  customDateRange?: [Dayjs, Dayjs] | [],
  sinceDateRange?: [Dayjs, Dayjs] | [],
) => {
  const timestampProps: any = {};
  if (granularity !== undefined) timestampProps.granularity = granularity;
  if (dateRange !== undefined) {
    let range: any = {};
    if (dateRange === DateRangeEnum.CUSTOM && customDateRange) {
      range = getDatesFromCustomRange(customDateRange);
      timestampProps.from_timestamp = range.from_timestamp;
      timestampProps.to_timestamp = range.to_timestamp;
    } else if (dateRange === DateRangeEnum.SINCE && sinceDateRange) {
      range = getDatesFromCustomRange(sinceDateRange);
      timestampProps.from_timestamp = range.from_timestamp;
      timestampProps.to_timestamp = range.to_timestamp;
    }
    timestampProps.date_range_type = dateRange;
  }
  return timestampProps;
};

export const getFormattedCompare = (compare: any[] | undefined) => {
  if (compare === undefined) return null;
  const type = compare[0];
  if (type === CompareType.TIME_PERIOD && compare.length === 2) {
    const subtype = compare[1];
    const compareObj = {
      type,
      sub_type: subtype,
    };
    return compareObj;
  }
  return null;
};

export const formatDate = (granularity: GranularityEnum, datetimeString: number): string => {
  const datetime = dayjs.unix(datetimeString);
  switch (granularity) {
    case GranularityEnum.MINUTE: {
      return datetime.format('h:mm A');
    }

    case GranularityEnum.HOUR: {
      return datetime.format('MMM D, hh:mm A');
    }

    case GranularityEnum.DAY: {
      return datetime.format('MMM D');
    }

    case GranularityEnum.WEEK: {
      const weekStart = datetime.startOf('week');
      const weekEnd = datetime.endOf('week');
      return `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')}`;
    }

    case GranularityEnum.MONTH: {
      return datetime.format('MMMM YYYY');
    }

    case GranularityEnum.QUARTER: {
      const quarterStartDate = dayjs(datetime)
        .startOf('month')
        .subtract(datetime.month() % 3, 'month');
      const quarterEndDate = dayjs(quarterStartDate).add(2, 'month').endOf('month');
      return `${quarterStartDate.format('MMM D')} - ${quarterEndDate.format('MMM D')}`;
    }

    default:
      throw new Error(`Invalid granularity: ${granularity}`);
  }
};

export const getGraphItemColor = (index: number): string => GRAPH_COLORS[index % GRAPH_COLORS.length];

const getTextWidth = (text: string, font = '14px -apple-system') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return Math.round(metrics.width + 40);
  }
  return null;
};

export const getColumnWidth = (columnTitle: string): number => {
  const width = getTextWidth(columnTitle);
  if (width) return width;
  return 120;
};

export const addLineChartData = (breakdownColums: any, dataPoints: any, chartData: ChartData, isPastData: boolean) => {
  const currentChartDataLength = chartData.length;
  Object.keys(dataPoints).forEach((dp) => {
    const obj = {
      date: dayjs(dp).unix(),
      value: dataPoints[dp],
    };
    const key = isPastData
      ? Object.values(breakdownColums).join('/').concat('(Past)')
      : Object.values(breakdownColums).join('/');
    const existingIndex = (chartData as LineChartData[]).findIndex((item) => item.series === key);
    if (existingIndex === -1) {
      (chartData as LineChartData[]).push({
        series: key,
        key,
        color: getGraphItemColor(currentChartDataLength),
        data: [obj],
      });
    } else {
      (chartData as LineChartData[])[existingIndex].data.push(obj);
    }
  });
};

export function compareStepName(stepIndex: number, compareStepsCount: number): string {
  let result = `${stepIndex}A`;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < compareStepsCount; i++) {
    result += ` or ${stepIndex}${String.fromCharCode(65 + i)}`;
  }
  return result;
}

export function compareStepColumnKey(stepIndex: number): string {
  return `$ored${stepIndex}`;
}

export const covertFunnelEventsCompareCombinations = (commaSeperatedCombinations: string[]): string[][] =>
  commaSeperatedCombinations.map((combination) => combination.split(','));

export const eventCompareStepNaming = (steps: FunnelStep[]): string[] =>
  steps.map((step, stepIndex) => {
    if (checkFunnelStepHasEventCompare(step)) {
      return compareStepName(stepIndex + 1, step.compare!.length);
    }
    return step.step_label!;
  });

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { LeftSidepanel } from '@src/client/v2/components/sidepanel';
import { leftSidepanelOpenState } from '@src/client/v2/components/sidepanel/state';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { cn } from '../../../ui-library/utils';
import { HomeScreenNavItemData, HomeScreenNavItemKey, HomeScreenNavItems } from '../constants';
import { HomeNavItemType } from '../types';
import DashboardNavLinks from './DashboardNavLinks';

const HomeNavLinkClassNames =
  'px-2 py-2 rounded-md flex items-center cursor pointer mb-2 hover:bg-gray-100 dark:text-foreground-light dark:hover:text-foreground dark:hover:bg-foreground-secondary';

function NavItem({
  active,
  navItemData,
  navItemKey,
  isSidepanelOpen,
  setIsSidepanelOpen,
}: {
  active: boolean;
  navItemData: HomeNavItemType;
  navItemKey: HomeScreenNavItemKey;
  isSidepanelOpen: boolean;
  setIsSidepanelOpen: SetterOrUpdater<boolean>;
}) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const [showMyBoards, setShowMyBoards] = useState(true);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (navItemKey === HomeScreenNavItemKey.YourBoards) {
      if (!isSidepanelOpen) {
        setIsSidepanelOpen(true);
        setShowMyBoards(true);
      } else {
        setShowMyBoards(!showMyBoards);
      }
      return;
    }
    navigate(getLinkWithWorkspace(navItemData.path));
  }, [navItemKey, navItemData.path, showMyBoards, isSidepanelOpen, setIsSidepanelOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const classNamesToApply = cn(
    HomeNavLinkClassNames,
    active
      ? 'bg-gray-200 text-foreground dark:text-foreground dark:bg-foreground-secondary flex items-center justify-between'
      : 'flex items-center justify-between',
  );

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        key={navItemData.title}
        onClick={handleClick}
        onKeyDown={handleClick}
        className={classNamesToApply}
      >
        <div className="inline-flex items-center">
          {navItemData.icon}
          {isSidepanelOpen ? <div className="ml-3 text-md font-semibold truncate">{navItemData.title}</div> : null}
        </div>
        {isSidepanelOpen && navItemKey === HomeScreenNavItemKey.YourBoards ? (
          <ChevronDownIcon className={`font-semibold h-6 w-6 ${showMyBoards ? 'rotate-180' : ''}`} />
        ) : null}
      </div>
      {navItemKey === HomeScreenNavItemKey.YourBoards && isSidepanelOpen && showMyBoards ? (
        <div className="ml-8">
          <DashboardNavLinks />
        </div>
      ) : null}
    </div>
  );
}

export default function HomeLeftNavigationPanel() {
  const location = useLocation();
  const [isSidepanelOpen, setIsSidepanelOpen] = useRecoilState(leftSidepanelOpenState);

  return (
    <LeftSidepanel>
      <>
        {HomeScreenNavItems.map((navDatakey) => {
          const navItemData = HomeScreenNavItemData[navDatakey];
          return (
            <NavItem
              key={navItemData.title}
              active={navDatakey !== HomeScreenNavItemKey.YourBoards && location.pathname.includes(navItemData.path)}
              navItemData={navItemData}
              navItemKey={navDatakey}
              isSidepanelOpen={isSidepanelOpen}
              setIsSidepanelOpen={setIsSidepanelOpen}
            />
          );
        })}
      </>
    </LeftSidepanel>
  );
}

import { isDemoUrl } from '@src/client/lib/utils';
import CreatedByFilter from '@src/client/v2/components/filters-and-selectors/created-by-filter';
import ReportTypeFilter from '@src/client/v2/components/filters-and-selectors/report-type-filter';
import { RightSidepanel } from '@src/client/v2/components/sidepanel';
import { rightSidepanelOpenState } from '@src/client/v2/components/sidepanel/state';
import { useRecoilValue } from 'recoil';

export default function HomeRightPanel({ hideCreatedBy }: { hideCreatedBy: boolean }) {
  const isSidepanelOpen = useRecoilValue(rightSidepanelOpenState);

  return (
    <RightSidepanel>
      {isSidepanelOpen ? (
        <>
          <p className="text-sm font-semibold mb-3">Filters</p>
          <div className="flex flex-col space-y-3">
            <ReportTypeFilter />
            {hideCreatedBy || isDemoUrl() ? null : <CreatedByFilter />}
          </div>
        </>
      ) : null}
    </RightSidepanel>
  );
}

/* eslint-disable import/prefer-default-export */
import { PluralSupportedTimeUnits, Workspace } from '@src/client/lib/api/types/response';
import { atom, selector } from 'recoil';

import { FlowsExpandedEvent } from '../features/flows/types';
import {
  ChartType,
  FlowsEventFilterType,
  FunnelCountTypes,
  FunnelTrendStep,
  FunnelTrendType,
  FunnelType,
  GranularityEnum,
  PropertyAttribution,
  ViewMode,
} from '../features/reports/constants';
import {
  Breakdown,
  Dimension,
  Filter,
  Formula,
  FunnelConversionTimeWindow,
  FunnelStep,
} from '../features/reports/types';
import { UserInfo } from '../lib/api/types/response';
import { getEmptyFunnelStepsState } from './recoilStateHelpers';

export const userInfoState = atom<UserInfo | null>({
  key: 'userInfoState',
  default: null,
});

export const reportNameState = atom<string>({
  key: 'reportNameState',
  default: '',
});

export const reportDescriptionState = atom<string>({
  key: 'reportDescriptionState',
  default: '',
});

export const dimensionsState = atom<Dimension[]>({
  key: 'dimensionsState',
  default: [],
});

export const funnelStepState = atom<FunnelStep[]>({
  key: 'funnelStepState',
  default: getEmptyFunnelStepsState(),
});

export const breakdownsState = atom<Breakdown[]>({
  key: 'breakdownsState',
  default: [],
});

export const globalFiltersState = atom<Filter[]>({
  key: 'globalFiltersState',
  default: [],
});

export const formulasState = atom<Formula[]>({
  key: 'formulasState',
  default: [],
});

export const granularityState = atom<GranularityEnum>({
  key: 'granularityState',
  default: GranularityEnum.DAY,
});

export const chartTypeState = atom<ChartType>({
  key: 'chartTypeState',
  default: ChartType.LINE,
});

export const compareState = atom<string[]>({
  key: 'compareState',
  default: [],
});

export const runIdState = atom<string | undefined>({
  key: 'runId',
  default: undefined,
});

export const funnelConversionTimeWindowState = atom<FunnelConversionTimeWindow>({
  key: 'funnelConversionTimeWindowState',
  default: {
    number: 14,
    unit: PluralSupportedTimeUnits.DAYS,
  },
});

export const funnelConversionFunctionState = atom<FunnelCountTypes>({
  key: 'funnelConversionFunctionState',
  default: FunnelCountTypes.TOTALS,
});

export const rowsSelectionState = atom<React.Key[]>({
  key: 'rowsSelectionState',
  default: [],
});

export const funnelTypeState = atom<FunnelType>({
  key: 'funnelTypeState',
  default: FunnelType.STEPS,
});

export const funnelTrendTypeState = atom<FunnelTrendType>({
  key: 'funnelTrendTypeState',
  default: FunnelTrendType.CONVERSION,
});

export const viewModeState = atom<ViewMode>({
  key: 'viewModeState',
  default: ViewMode.LINEAR,
});

export const funnelTrendStepState = atom<FunnelTrendStep>({
  key: 'funnelTrendStepState',
  default: FunnelTrendStep.ALL_STEPS,
});

export const propertyAttributionState = atom<PropertyAttribution>({
  key: 'propertyAttribution',
  default: PropertyAttribution.LAST_TOUCH,
});

export const activeWorkspaceState = atom<Workspace | null>({
  key: 'activeWorkspaceState',
  default: null,
});

export const isCompareEnabledInFunnelStepsState = selector({
  key: 'isCompareEnabledInFunnelStepsState',
  get: ({ get }) => {
    const funnelSteps = get(funnelStepState);

    return funnelSteps.some((step) => !!step.compare);
  },
});

/* Flows States */
export const flowsRowCountState = atom<number>({
  key: 'flowsRowCountState',
  default: 3,
});

export const flowsEventFilterTypeState = atom<FlowsEventFilterType>({
  key: 'flowsEventFilterTypeState',
  default: undefined,
});

export const flowsExcludeEventsState = atom<string[]>({
  key: 'flowsExcludeEventsState',
  default: [],
});

export const flowsIncludeEventsState = atom<string[]>({
  key: 'flowsIncludeEventsState',
  default: [],
});

export const flowsExpandedEventsListState = atom<FlowsExpandedEvent[]>({
  key: 'flowsExpandedEventsListState',
  default: [],
});

export const sankeyStepsBreakpointsState = selector({
  key: 'sankeyStepsBreakpointsState',
  get: ({ get }) => {
    const dimensions = get(dimensionsState);
    const breakpoints: number[] = [];

    if (dimensions.length > 1) {
      let lastbreakpoint = 0;
      dimensions.forEach((dimension) => {
        const dimensionStepCount = (dimension.steps_after ?? 0) + (dimension.steps_before ?? 0) + 1;
        breakpoints.push(dimensionStepCount + lastbreakpoint);
        lastbreakpoint += dimensionStepCount;
      });
    }
    return breakpoints;
  },
});

import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type UIVersion = 'v1' | 'v2';

type UIVersionProviderProps = {
  children: React.ReactNode;
  defaultUI?: UIVersion;
  storageKey?: string;
};

type UIVersionState = {
  uiVersion: UIVersion;
  setUIVersion: (uiVersion: UIVersion) => void;
};

const initialState: UIVersionState = {
  uiVersion: 'v2',
  setUIVersion: () => null,
};

const UIVersionContext = createContext<UIVersionState>(initialState);

export const UIStoragKey = 'pi-ui-version';

export function UIVersionProvider({
  children,
  defaultUI = 'v2',
  storageKey = UIStoragKey,
  ...props
}: UIVersionProviderProps) {
  const [activeUIVersion, setActiveUIVersion] = useState<UIVersion>(
    () => (localStorage.getItem(storageKey) as UIVersion) || defaultUI,
  );

  useEffect(() => {}, []);

  const value = useMemo(
    () => ({
      uiVersion: activeUIVersion,
      setUIVersion: (uiVersion: UIVersion) => {
        localStorage.setItem(storageKey, uiVersion);
        setActiveUIVersion(uiVersion);
      },
    }),
    [activeUIVersion, storageKey],
  );

  return (
    <UIVersionContext.Provider {...props} value={value}>
      {children}
    </UIVersionContext.Provider>
  );
}

export const useUIVersion = () => {
  const context = useContext(UIVersionContext);

  if (context === undefined) throw new Error('useUIVersion must be used within a UiVersionProvider');

  return context;
};

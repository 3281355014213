/* eslint-disable  import/prefer-default-export */
import { Image, Tooltip } from 'antd';
import { NotificationInstance, NotificationPlacement } from 'antd/es/notification/interface';

import { PlatformItemType } from './api/types/request';
import { ReportType } from './api/types/response';

export function getProductIcon(itemType: PlatformItemType, reportType: ReportType | null, maxHeight = '128px') {
  if (itemType === PlatformItemType.REPORT && reportType === ReportType.INSIGHT) {
    return (
      <Tooltip title="Insights">
        <Image preview={false} alt="Insights" style={{ maxHeight }} src="/images/line-chart.png" />
      </Tooltip>
    );
  }
  if (itemType === PlatformItemType.REPORT && reportType === ReportType.FUNNEL) {
    return (
      <Tooltip title="Funnels">
        <Image preview={false} alt="Funnels" style={{ maxHeight }} src="/images/bar-chart.png" />
      </Tooltip>
    );
  }
  if (itemType === PlatformItemType.DASHBOARD) {
    return (
      <Tooltip title="Dashboards">
        <Image preview={false} style={{ maxHeight }} src="/images/dashboard.png" />
      </Tooltip>
    );
  }
  if (itemType === PlatformItemType.COHORT) {
    return (
      <Tooltip title="Cohorts">
        <Image preview={false} style={{ maxHeight }} src="/images/cohorts.png" />
      </Tooltip>
    );
  }

  if (itemType === PlatformItemType.CAMPAIGN) {
    return (
      <Tooltip title="Cohorts">
        <Image preview={false} style={{ maxHeight }} src="/images/cohorts.png" />
      </Tooltip>
    );
  }

  return null;
}

export const sendNotification = (
  notification: NotificationInstance,
  type: 'success' | 'warning' | 'info' | 'error' | 'open',
  title: string,
  description: string,
  placement: NotificationPlacement = 'bottomRight',
  duration = 6,
) => {
  switch (type) {
    case 'success':
      return notification.success({
        message: title,
        description,
        duration,
        placement,
      });
    case 'info':
      return notification.info({
        message: title,
        description,
        duration,
        placement,
      });
    case 'error':
      return notification.error({
        message: title,
        description,
        duration,
        placement,
      });
    case 'warning':
      return notification.warning({
        message: title,
        description,
        duration,
        placement,
      });
    case 'open':
      return notification.open({
        message: title,
        description,
        duration,
        placement,
      });
    default:
  }
  return null;
};

export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()).replaceAll('_', ' ');

export const snakeCaseToTitleCase = (str: any) => {
  const words = str.split('_');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = toTitleCase(words[i]);
  }
  return words.join(' ');
};

export function getTimePassed(timestamp: number): string {
  const now = new Date().getTime();
  const timeDifference = now - timestamp;

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30.44 * day; // Approximate days in a month
  const year = 365.25 * day; // Approximate days in a year

  if (timeDifference < minute) {
    return `${Math.floor(timeDifference / 1000)} seconds ago`;
  }
  if (timeDifference < hour) {
    return `${Math.floor(timeDifference / minute)} minutes ago`;
  }
  if (timeDifference < day) {
    return `${Math.floor(timeDifference / hour)} hours ago`;
  }
  if (timeDifference < week) {
    return `${Math.floor(timeDifference / day)} days ago`;
  }
  if (timeDifference < month) {
    return `${Math.floor(timeDifference / week)} weeks ago`;
  }
  if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return months === 1 ? '1 month ago' : `${months} months ago`;
  }
  const years = Math.floor(timeDifference / year);
  return years === 1 ? '1 year ago' : `${years} years ago`;
}

import {
  AlertRule,
  AlertSubscription,
  AlertSupportedFrequencies,
  AlertSupportedSeverities,
  AlertSupportedStatuses,
  AlertTypes,
} from '@src/client/features/alerts/types';

import { CustomEventParams, CustomEventStatus, CustomEventType, DashboardLayout, ReportType } from './response';

export enum ItemAccessLevel {
  OWNER = 'OWNER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  READER = 'READER',
}

export enum ItemScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum ItemStatus {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type NullableFormData = FormData | null;

export interface EmptyRequestParams {}

// DP Request Types
export interface ListUsersLikeRequestParams {
  email: string;
}

export interface ListTagsLikeRequestParams {
  name: string;
}

// ML Platform Request Types
export interface GenericItemRequestParams {
  item_external_id: string;
}

export interface DataSourceTypeRequestParams {
  dataSourceType: DataSourceType;
}

export interface DataSourceAlertRequestParams {
  dataSourceType: DataSourceType;
  queryType?: string;
}

export enum SecretType {
  SECRET = 'SECRET',
  VOLUME = 'VOLUME',
}

export interface AccessLevelPerUser {
  access_level: ItemAccessLevel;
  user_email: string;
}

export interface ItemsIncremental {
  limit: number;
  offset: number;
  filter_text: string;
  order_by: string;
  order_type: string;
}

export interface AutoCorrectSearchParams {
  query: string;
}

export interface JobRunLogRequestParams {
  data_item_external_id: string;
  topN: number;
  job_type: 'data_item_run';
}

export enum TaskType {
  SCHEDULE = 'SCHEDULE',
  EVENT_HOOK = 'EVENT_HOOK',
}

export enum TaskTriggerMode {
  POST_INGEST = 'POST_INGEST',
  ON_INGEST = 'ON_INGEST',
}

export enum ComputeType {
  DATABRICKS = 'DATABRICKS',
  KUBERNETES = 'KUBERNETES',
  NATIVE = 'NATIVE',
}

export enum SeverityLevel {
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface DetectItem {
  itemExternalId: string;
  configId: string;
  itemName: string;
  problemAreaName: string;
  itemScope: ItemScope;
  algorithms: DetectAlgorithmInput[];
  dataSourceId: string;
  dataSourceItemExtId: string | null;
  dataSourceItemPath: string | null;
  categoricalDimensionColumns: string[] | null;
  continuousDimensionColumns: string[] | null;
  measureColumn: string | null;
  mainDimensionColumn: string | null;
  timestampColumn: string | null;
  granularity: number | null;
  timePeriod: number | null;
  ignoreLatest: number | null;
  taskType: TaskType | null;
  taskConfig: any | null;
  taskTriggerMode: TaskTriggerMode | null;
  triggerEndpoint: string | null;
  computeType: ComputeType | null;
  configParameters: any | null;
  dataSourceType: DataSourceType | null;
  aggregate: boolean | null;
  aggregationRule: string | null;
  fillMissingDates: boolean | null;
}

export interface UpdateDetectItem {
  itemExtId: string;
  item: DetectItem;
}

export enum DataSourceType {
  DATA_PLATFORM = 'DATA_PLATFORM',
  PROMETHEUS = 'PROMETHEUS',
}

export interface AddDataSourceRequest {
  dataSourceType: DataSourceType;
  dataSourceParameters: any;
}

export interface GetDataSourceRequest {
  dataSourceId: string;
}

export interface RequestByItemExtId {
  itemExtId: string;
}

export interface DetectGetItemRequest {
  itemExtId: string;
  configId: string | null;
}

export interface DetectGetMultipleItemsRequest {
  itemExtIds: string[];
}

export interface DetectGetGroupItemRequest {
  itemExtId: string;
  groupVersionId: string | null;
}

export enum OrderBy {
  itemId = 'itemExternalId',
  itemName = 'itemName',
  problemArea = 'problemArea',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
}

export enum OrderType {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum AnomalyMode {
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  UPPER_AND_LOWER = 'UPPER_AND_LOWER',
}

export interface DetectGetUserGroupItemsRequest {
  configStatus: ItemStatus;
  // filterText: string
  // orderBy: OrderBy
  // orderType: OrderType
  limit: number;
  offset: number;
  // showPublic: boolean
  // groupType: ItemType
}

export interface GetUserReportRequest {
  configStatus: ItemStatus;
  limit: number;
  offset: number;
  reportType: ReportType;
  currentUserOnly: boolean;
}

export interface GetUserCohortCountRequest {
  configStatus: ItemStatus;
  currentUserOnly: boolean;
}

export interface GetReportCountRequest {
  configStatus: ItemStatus;
  reportType: ReportType;
  currentUserOnly: boolean;
}

export interface GetUserCohortRequest {
  configStatus: ItemStatus;
  limit: number;
  offset: number;
  currentUserOnly: boolean;
}

export interface GetRecentItemsRequest {
  configStatus: ItemStatus;
  limit: number;
  offset: number;
}

export interface DetectCollectionGraphNode {
  parent: string[];
  children: string[];
}

export interface DetectGetUserItemsRequest {
  configStatus: ItemStatus;
  filterText: string;
  orderBy: OrderBy;
  orderType: OrderType;
  limit: number;
  offset: number;
  showPublic: boolean;
}

export interface DetectGetUserItemsCountRequest {
  configStatus: ItemStatus;
  filterText: string;
}

export interface GrantItemAccessRequest {
  item_id: string;
  item_external_id: string;
  item_access_level: ItemAccessLevel;
  user_email_list: string[];
}

export interface DeleteItemAccessRequest {
  item_id: string;
  item_external_id: string;
  access_per_user: {
    user_email: string;
    access_level: ItemAccessLevel;
  };
}

export interface DetectModerateRequest {
  itemExternalId: string;
  configId: string;
  requestStatus: ItemStatus;
}

export interface DetectModerateGroupRequest {
  itemExternalId: string;
  groupVersionId: string;
  requestStatus: ItemStatus;
}

export interface DetectGetResultRequest {
  itemExtId: string;
  anomalyId: string | null;
}

export interface DetectGetResultFromConfigRequest {
  configId: string;
  anomalyId: string | null;
}

export interface DetectResultForecastRequest {
  filepath: string;
  tsCol: string;
  measureCol: string;
  granularity: number;
  configAlgorithmId: string;
}

export interface DetectAlgorithmInput {
  algorithm: string;
  hyperParameters: any;
  severity: SeverityLevel;
  squadcastSlug: string;
  sensitivity: number;
  configAlgorithmId: string | null;
  anomalyMode: AnomalyMode;
}

export interface UdaanServiceRequest {
  cluster: string;
  namespace: string;
}

export interface UdaanResourceRequest {
  cluster: string;
  namespace: string;
  serviceName: string;
}

export interface CreateReportRequest {
  itemName: string;
  itemDescription: string;
  itemScope: string;
  params: any;
  reportType: ReportType;
  isSaved: boolean;
}

export interface CreateCohortRequest {
  itemName: string;
  itemDescription: string;
  itemScope: string;
  params: any;
}

export interface UpdateReportRequest {
  configId: string;
  projectId?: string;
  tenantId?: string;
  itemId: string;
  itemExternalId: string;
  itemName: string;
  itemDescription: string;
  itemScope: ItemScope;
  params: { [key: string]: any };
  isConfigModified: boolean;
  reportType: ReportType;
  isSaved: boolean;
  traceId?: number; // used by UI internally to identify last request
}

export interface LlmCreateReportRequest {
  inputString: string;
}

export interface UpdateCohortRequest {
  projectId?: string;
  tenantId?: string;
  itemExternalId: string;
  itemName: string;
  itemDescription: string;
  itemScope: ItemScope;
  params: { [key: string]: any };
}

export enum PlatformItemType {
  COHORT = 'COHORT',
  REPORT = 'REPORT',
  DASHBOARD = 'DASHBOARD',
  EVENT = 'EVENT',
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  CAMPAIGN = 'CAMPAIGN',
  ALERT = 'ALERT',
  RETENTION = 'RETENTION',
}

export interface GetDashboardRequest {
  configStatus: ItemStatus;
  limit: number;
  offset: number;
  currentUserOnly: boolean;
}

export interface GetDashboardCountRequest {
  configStatus: ItemStatus;
  currentUserOnly: boolean;
}
export interface CreateDashboardRequest {
  name: string;
  description: string;
  projectId: string;
  layout: DashboardLayout[];
}
export interface UpdateDashboardRequest {
  itemExtId: string;
  name: string;
  description: string;
  layout: DashboardLayout[];
}

export interface GetSearchRequest {
  createdBy: string[];
  itemType: PlatformItemType[];
  reportType: ReportType[];
  filterText: string;
  limit: number;
  offset: number;
}

export interface GetSearchCreatorRequest {
  filterText: string;
  limit: number;
  offset: number;
}

export interface CreateEventRequest {
  itemScope: string;
  params: any;
}

export interface UpdateEventRequest {
  itemExternalId: string;
  itemScope: string;
  params: any;
}

export interface UpdateLastActiveWorkspaceId {
  lastActiveWorkspaceId: string | null;
}

export interface CreateAlertRequest {
  alertType: AlertTypes;
  reportId: string;
  name: string;
  description?: string;
  rules: AlertRule;
  frequency: AlertSupportedFrequencies;
  subscriptions: AlertSubscription[];
  alertStatus: AlertSupportedStatuses;
  alertSeverity: AlertSupportedSeverities;
  isAnalysisDashboard: boolean;
  dashboardReportList?: string[];
}

export interface UpdateAlertRequest {
  alertItemExtId: string;
  dashboardReportList: string[];
}

export interface CreateAlertResponse {
  itemExternalId: string;
  configId: string;
  alertStatus: AlertSupportedStatuses;
}

/* Custom Event Req interfaces start */
export interface GetCustomEventsRequest {
  limit: number;
  offset: number;
  currentUserOnly: boolean;
  status?: CustomEventStatus;
  type?: CustomEventType;
}

export interface GetCustomEventsCountRequest {
  currentUserOnly: boolean;
}

/* Custom Event Req interfaces end */

export interface CreateCustomEventRequest {
  name: string;
  description?: string;
  type: CustomEventType;
  params: CustomEventParams;
}

export enum OauthProvider {
  google = 'GOOGLE_OAUTH2',
  microsoft = 'MS_OAUTH2',
}

export interface LlmReportFeedback {
  chatId: string;
  feedback: string;
}

export interface GetUserAlertRequest {
  limit: number;
  offset: number;
  currentUserOnly: boolean;
}

export interface GetUserAlertCountRequest {
  currentUserOnly: boolean;
}

export interface AlertExtId {
  alertItemExtId: string;
}

export interface AlertItemId {
  alertItemId: string;
}

export interface GetAnomaliesForReportRequest {
  reportItemExtId: string;
  offset: number;
  limit: number;
}

export interface GetListRequest {
  offset: number;
  limit: number;
}

export interface GetUsersInTeamRequest {
  teamId: string;
  offset: number;
  limit: number;
}

export interface TeamUsersRequest {
  teamId: string;
  userIds: string[];
}

export interface UpdateTeamRequest {
  teamId: string;
  name: string;
  description?: string;
}

export interface SearchDimensionValuesRequest {
  dimension: string;
  searchString: string;
  limit: number;
  offset: number;
}

export interface GetRecentlyViewedRequest {
  // configStatus: ItemStatus;
  limit: number;
  offset: number;
  createdBy?: string[] | null;
  itemType?: Array<PlatformItemType> | null;
  reportType?: Array<ReportType> | null;
  filterText?: string;
  orderType?: 'DESC' | 'ASC';
  orderBy?: string;
  currentUserOnly?: boolean;
}

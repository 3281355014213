import { getDashboardSummariesByUser } from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { ItemStatus } from '@src/client/lib/api/types/request';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { PlusIcon } from '@src/client/v2/ui-library/icons/HomepageIcons';
import { ReportsMenuIcon } from '@src/client/v2/ui-library/icons/NavbarIcons';
import { Skeleton } from '@src/client/v2/ui-library/skeleton';
import { cn } from '@src/client/v2/ui-library/utils';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

const DefaultClassNames =
  'px-2 py-2 rounded-md flex items-center cursor pointer mb-2 hover:bg-gray-100 dark:text-foreground-light dark:hover:text-foreground dark:hover:bg-foreground-secondary';
const ActiveClassNames = 'bg-gray-200 text-foreground dark:text-foreground dark:bg-foreground-secondary';

export default function DashboardNavLinks() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery(
    [QueryKeys.GetDashboardSummariesByUser, true],
    () =>
      getDashboardSummariesByUser({
        offset: 0,
        configStatus: ItemStatus.APPROVED,
        limit: 100,
        currentUserOnly: true,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return isLoading ? (
    <>
      {[...Array(5).keys()].map((i) => (
        <Skeleton key={i} className="h-6 w-full mb-3" />
      ))}
    </>
  ) : (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={() => navigate(getLinkWithWorkspace(`dashboard`))}
        onKeyDown={() => navigate(getLinkWithWorkspace(`dashboard`))}
        className={cn(
          DefaultClassNames,
          location.pathname.split('/').length === 3 && location.pathname.split('/')[2] === 'dashboard'
            ? ActiveClassNames
            : '',
        )}
      >
        <PlusIcon />
        <div className="ml-3 text-sm font-semibold">Create new</div>
      </div>
      {(data ?? []).map((dashboardData) => {
        const classNamesToApply = cn(
          DefaultClassNames,
          location.pathname.includes(dashboardData.itemExternalId) ? ActiveClassNames : '',
        );
        return (
          <div
            key={dashboardData.configId}
            role="button"
            tabIndex={0}
            onClick={() => navigate(getLinkWithWorkspace(`dashboard/${dashboardData.itemExternalId}`))}
            onKeyDown={() => navigate(getLinkWithWorkspace(`dashboard/${dashboardData.itemExternalId}`))}
            className={classNamesToApply}
          >
            <ReportsMenuIcon />
            <div className="ml-3 text-sm font-semibold">{dashboardData.name}</div>
          </div>
        );
      })}
      {/* <div
        role="button"
        className={cn(DefaultClassNames, 'text-left')}
      >
        <div className="ml-3 text-sm font-semibold">View all boards</div>
      </div> */}
    </>
  );
}

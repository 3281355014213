import { Dimension } from '@src/client/v2/helpers/reports/types';
import { atom, selector } from 'recoil';

export const dimensionsStateV2 = atom<Dimension[]>({
  key: 'dimensionsStateV2',
  default: [],
});

export const flowsStepCountState = selector({
  key: 'flowsStepCountState',
  get: ({ get }) => {
    const dimensions = get(dimensionsStateV2);

    return (
      dimensions.reduce((accm, item) => accm + (item.steps_after ?? 0) + (item.steps_before ?? 0) + 1, 0) +
      dimensions.length -
      1
    );
  },
});

import { ReactElement, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getUserInfoOld } from '../lib/api/queries/common';
import { saveActiveWorkspaceDetailsInLocalStorage } from '../lib/utils';
import { activeWorkspaceState } from '../recoil/atoms';
import { Button } from '../v2/ui-library/button';
import { SpinLoader } from '../v2/ui-library/loaders';

interface Props {
  routeAccessSelector: string;
  children: ReactElement;
}

function UnauthorizedAccess({ children, routeAccessSelector }: Props) {
  const navigate = useNavigate();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { data, status, isLoading } = useQuery(['userInfo', {}], getUserInfoOld, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
  const [showChildren, setShowChildren] = useState(false);
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const [activeWorkspace, setActiveWorkspace] = useRecoilState(activeWorkspaceState);

  useEffect(() => {
    if (!isLoading && status === 'success') {
      if (!data?.workspaces?.map((ws) => ws.id).includes(workspaceId ?? '')) {
        setActiveWorkspace(data?.workspaces[0]);
        saveActiveWorkspaceDetailsInLocalStorage(data?.workspaces[0].id, data?.workspaces[0].tenantId);
        setShowAccessDenied(true);
        setShowChildren(false);
        return;
      }
      if (!data?.accessScopes?.includes(routeAccessSelector)) {
        setShowAccessDenied(true);
        setShowChildren(false);
        return;
      }
      setShowChildren(true);
      setShowAccessDenied(false);
    }
  }, [isLoading, status, data, routeAccessSelector, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (showChildren) return children;

  if (showAccessDenied)
    return (
      <div className="flex h-screen bg-background">
        <div className="m-auto text-center">
          <img src="/images/v2/errors/403.svg" alt="Unauthorized" />
          <Button variant="primary" onClick={() => navigate(`/${activeWorkspace?.id}/home`)}>
            Go back home
          </Button>
        </div>
      </div>
    );

  return (
    <div className="h-full flex items-center justify-center bg-background" style={{ height: '100vh' }}>
      <SpinLoader />
    </div>
  );
}

export default UnauthorizedAccess;

import { granularityState } from '@src/client/recoil/atoms';
import {
  customDateRangeState,
  dateRangeState,
} from '@src/client/v2/components/filters-and-selectors/date-range-selector/state';
import { dimensionsStateV2 } from '@src/client/v2/components/filters-and-selectors/dimension-filter/state';
import { globalFiltersStateV2 } from '@src/client/v2/components/filters-and-selectors/global-property-filter/state';
import { SelectOptionsType } from '@src/client/v2/ui-library/select';
import { atom, useRecoilValue, useResetRecoilState } from 'recoil';

import { EventQueryBuilder } from './types';

export const eventPropertyListState = atom<string[]>({
  key: 'eventPropertyListState',
  default: [],
});

export const eventTableOffsetState = atom<number>({
  key: 'eventTableOffsetState',
  default: 0,
});

export const eventTableColumnListState = atom<SelectOptionsType[]>({
  key: 'eventTableColumnListState',
  default: [],
});

export const evenLastUpdatedState = atom<number | undefined>({
  key: 'evenLastUpdatedState',
  default: undefined,
});

export const eventRefetchState = atom<number>({
  key: 'eventRefetchState',
  default: 0,
});

export const eventConfigAndItemIdState = atom<{ itemExtId: string | null; configId: string | null }>({
  key: 'eventConfigAndItemIdState',
  default: {
    itemExtId: null,
    configId: null,
  },
});

/* Helpers */
export const useGetQueryBuilderDataForEvent = (): EventQueryBuilder => {
  // const dimensions = useRecoilValue(dimensionsState);
  // const globalFilters = useRecoilValue(globalFiltersState);
  const dimensions = useRecoilValue(dimensionsStateV2);
  const globalFilters = useRecoilValue(globalFiltersStateV2);
  const dateRange = useRecoilValue(dateRangeState);
  const customDateRange = useRecoilValue(customDateRangeState);
  const selectedColumnList = useRecoilValue(eventTableColumnListState);
  const eventPropertyList = useRecoilValue(eventPropertyListState);
  const granularity = useRecoilValue(granularityState);
  const offset = useRecoilValue(eventTableOffsetState);

  return {
    dimensions,
    'global-filters': globalFilters,
    dateRange,
    customDateRange,
    sinceDateRange: customDateRange,
    selectedColumnList,
    eventPropertyList,
    granularity,
    offset,
  };
};

export const useResetEventsDataStore = () => {
  const resetEventPropertyList = useResetRecoilState(eventPropertyListState);
  const resetEventOffset = useResetRecoilState(eventTableOffsetState);
  const resetEventTableColumns = useResetRecoilState(eventTableColumnListState);
  const resetIds = useResetRecoilState(eventConfigAndItemIdState);
  const resetGlobalFilters = useResetRecoilState(globalFiltersStateV2);
  const resetDimensions = useResetRecoilState(dimensionsStateV2);
  const resetEventRefecthState = useResetRecoilState(eventRefetchState);

  /* V1 state */
  // const resetDimensions = useResetRecoilState(dimensionsState);
  // const resetGlobalFilters = useResetRecoilState(globalFiltersState);
  const resetDateRange = useResetRecoilState(dateRangeState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);
  const resetGranularity = useResetRecoilState(granularityState);

  return () => {
    resetIds();
    resetEventPropertyList();
    resetEventOffset();
    resetEventTableColumns();
    resetEventRefecthState();

    resetDimensions();
    resetGlobalFilters();
    resetDateRange();
    resetCustomDateRange();
    resetGranularity();
  };
};

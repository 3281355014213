/* eslint-disable  no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { CaretSortIcon } from '@radix-ui/react-icons';
import { isLengthyArray } from '@src/client/lib/utils';
import GenericErrorView from '@src/client/v2/components/generic-error-view';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table';
import React, { CSSProperties, HTMLProps, useEffect, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

import { Button } from '../button';
import { Checkbox } from '../checkbox';
import { SpinLoader } from '../loaders';
import { Skeleton } from '../skeleton';
import { Table, TableCell, TableHead, TableRow } from './TablePrimitives';
import { VirtualTableProps } from './types';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData, TValue> {
    align?: 'left' | 'right';
    style?: CSSProperties;
    sortable?: boolean;
    sticky?: boolean;
  }
}

function getStickyColumnLeftPosition(index: number, columns: ColumnDef<any, any>[]): number {
  if (!index) return 0;

  const prevColumnsTotalWidth = columns
    .slice(0, index)
    .reduce((curr, column) => curr + Number(column.meta?.style?.width ?? 150), 0);
  return prevColumnsTotalWidth;
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line react-hooks/exhaustive-deps

  return <input type="checkbox" ref={ref} className={`${className} cursor-pointer`} {...rest} />;
}

/**
 *
 * NOTE: If you pass sticky true in meta info then make sure width is set else it will break
 */
export function VirtualTable<TData, TValue>({
  columns,
  data,
  loading,
  error,
  useWindowScroll = true,
  enableMultiRowSelection = false,
  filterValue,
  roundedTable,
  canRowExpand,
  onEndReached,
  retry,
  emptyDataText = 'No reports found',
  onRowSelectionChange,
  renderExpandedComponent,
  tableRootClassname = '',
  tableContainerClassname = '',
  hideFooter = false,
  getRowId,
  defaultSelectedRows = {},
  enableAllRowsSelection = false,
}: VirtualTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columnsToUse = useMemo(() => {
    if (!enableMultiRowSelection) return [...columns];

    return [
      {
        id: 'select',
        // header: ({ table }) => (
        //   <div className='px-1'></div>
        // ),
        meta: {
          style: { width: 48 },
          sticky: true,
        },
        header: (
          { table }: { table: ReactTable<TData> }, // eslint-disable-line react/no-unused-prop-types
        ) =>
          enableAllRowsSelection ? (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()} // or getToggleAllPageRowsSelectedHandler
            />
          ) : null,
        cell: (
          { row }: { row: Row<TData> }, // eslint-disable-line react/no-unused-prop-types
        ) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onCheckedChange={row.getToggleSelectedHandler()}
            className={
              (row.original as any).color ? `data-[state=checked]:bg-graphColors-${(row.original as any).color}` : ''
            }
          />
        ),
      },
      ...columns,
    ];
  }, [columns, enableMultiRowSelection, enableAllRowsSelection]);

  const table = useReactTable({
    data,
    columns: columnsToUse.map((column) => ({ ...column, sortingFn: column.sortingFn ?? 'alphanumeric' })),
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowCanExpand: () => !!canRowExpand,
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      rowSelection: defaultSelectedRows,
    },
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: (row, columnId, filterText) => {
      if (filterText.length < 1) return true;
      const safeValue = (() => {
        const value = row.getValue(columnId);
        return typeof value === 'number' ? String(value) : value;
      })() as string;

      return safeValue?.toLowerCase().includes(filterText.toLowerCase());
    },
    onGlobalFilterChange: setGlobalFilter,
    getRowId,
  });

  const selectedRows = table.getSelectedRowModel();

  const { rows } = table.getRowModel();

  useEffect(() => {
    if (typeof filterValue === 'string') {
      setGlobalFilter(filterValue);
    }
  }, [filterValue]);

  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(table.getSelectedRowModel().rows);
    }
  }, [selectedRows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableVirtuoso
      useWindowScroll={useWindowScroll}
      totalCount={(rows.length ?? 50) * (canRowExpand ? 2 : 1)}
      // totalCount={50}
      // overscan={50}
      endReached={onEndReached}
      components={{
        Table: ({ style, ...props }) => (
          <Table
            {...props}
            className={`${roundedTable ? '' : 'rounded-t-none border-t-0'} ${tableContainerClassname} no-scrollbar`}
            tableRootClassname={tableRootClassname}
          />
        ),
        TableRow: ({ ...props }) => {
          const itemIndex = props['data-index'];
          // NOTE: The reason why  we are doing this is for row expansion we inject a fake row in table with no entries which will be used by virtuoso for item count and height calculation
          const rowIndexToUse = canRowExpand ? Math.floor(itemIndex / 2) : itemIndex;
          const row = rows[rowIndexToUse];
          if (canRowExpand && itemIndex % 2 !== 0) {
            if (row.getIsExpanded()) {
              return (
                <TableRow {...props} className={row.getIsExpanded() ? 'border-b-0' : ''}>
                  <TableCell colSpan={row.getVisibleCells().length}>
                    {renderExpandedComponent ? renderExpandedComponent({ row }) : 'No data'}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow {...props} className={row.getIsExpanded() ? 'border-b-0' : ''}>
                <TableCell colSpan={row.getVisibleCells().length} className="p-0" />
              </TableRow>
            );
          }
          return (
            <TableRow {...props} className={row.getIsExpanded() ? 'border-b-0' : ''}>
              {row.getVisibleCells().map((cell, idx) => (
                <TableCell
                  key={cell.id}
                  className={`${(cell.column.columnDef.meta as any)?.align === 'right' ? 'text-right' : ''}`}
                  style={{
                    ...((cell.column.columnDef.meta as any)?.style ?? {}),
                    position: (cell.column.columnDef.meta as any)?.sticky ? 'sticky' : 'static',
                    left: (cell.column.columnDef.meta as any)?.sticky
                      ? getStickyColumnLeftPosition(idx, columnsToUse)
                      : 'unset',
                    zIndex: (cell.column.columnDef.meta as any)?.sticky ? 1 : 0,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          );
        },
        FillerRow: ({ ...props }) => (
          <TableRow {...props}>
            <TableCell colSpan={columns.length}>
              <Skeleton className="h-6 w-full" />
            </TableCell>
          </TableRow>
        ),
      }}
      fixedHeaderContent={() =>
        table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="bg-gray-50 dark:bg-gray-700">
            {headerGroup.headers.map((header, index) => (
              <TableHead
                key={header.id}
                style={{
                  ...((header.column.columnDef.meta as any)?.style ?? {}),
                  position: (header.column.columnDef.meta as any)?.sticky ? 'sticky' : 'static',
                  left: (header.column.columnDef.meta as any)?.sticky
                    ? getStickyColumnLeftPosition(index, columnsToUse)
                    : 'unset',
                  zIndex: (header.column.columnDef.meta as any)?.sticky ? 1 : 0,
                }}
                colSpan={header.colSpan}
              >
                <div
                  className={`flex items-center ${
                    (header.column.columnDef.meta as any)?.align === 'right' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <span>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                  {(header.column.columnDef.meta as any)?.sortable ? (
                    <Button
                      variant="icon"
                      onClick={() => header.column.toggleSorting(header.column.getIsSorted() === 'asc')}
                      className="-mr-2"
                    >
                      <CaretSortIcon />
                    </Button>
                  ) : null}
                </div>
              </TableHead>
            ))}
          </TableRow>
        ))
      }
      fixedFooterContent={() =>
        hideFooter ? null : isLengthyArray(data) ? (
          <TableRow>
            <TableCell colSpan={columnsToUse.length}>
              {loading ? (
                <SpinLoader size="small" />
              ) : (
                <p className="text-xs text-center font-semibold opacity-60">{`<- End of list reached ->`}</p>
              )}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell colSpan={columnsToUse.length}>
              {loading ? (
                <SpinLoader size="small" />
              ) : error ? (
                <div className="mt-10">
                  <GenericErrorView error={error} retry={retry} />
                </div>
              ) : (
                <div className="flex flex-col items-center w-full">
                  <img src="/images/v2/empty_result.png" width={300} alt="Empty Reports Response illustration" />
                  <div className="text-center px-3 mb-12">
                    <p className="font-bold text-sm">{emptyDataText}</p>
                  </div>
                </div>
              )}
            </TableCell>
          </TableRow>
        )
      }
    />
  );
}

import { ReportType } from '@src/client/lib/api/types/response';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { useIsFlowDisabled } from '@src/client/v2/helpers/hooks';
import { useTheme } from '@src/client/v2/ui-library/theme-provider';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import { PlusIcon } from '../../../ui-library/icons/HomepageIcons';

const ReportItems = [
  {
    type: ReportType.INSIGHT,
    title: 'Create Insights',
    imageSrc: '/images/v2/insights-small.png',
    path: 'insights',
  },
  {
    type: ReportType.FUNNEL,
    title: 'Create Funnel',
    imageSrc: '/images/v2/funnels-small.png',
    path: 'funnels',
  },
  {
    type: ReportType.FLOW,
    title: 'Create Flow',
    imageSrc: '/images/v2/flows-small.png',
    path: 'flows',
  },
  {
    type: ReportType.RETENTION,
    title: 'Create Retention',
    imageSrc: '/images/v2/retention-small.png',
    path: 'retention',
  },
];
export default function CreateReportPills() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const { theme } = useTheme();
  const hideFlow = useIsFlowDisabled();
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  });

  return (
    <div ref={ref}>
      {width > 0 ? (
        <div className="flex items-center gap-3 mb-4 no-scrollbar overflow-x-scroll" style={{ maxWidth: width }}>
          {ReportItems.map((item) => {
            if (item.path === 'flows' && hideFlow) return null;
            return (
              <Link to={getLinkWithWorkspace(item.path)} key={item.path}>
                <div className="flex items-center justify-between border rounded-xl border-border bg-transparent w-[240px] px-3 py-2 cursor-pointer hover:bg-primary-light dark:hover:bg-foreground-secondary">
                  <div className="flex items-center">
                    <img height={32} width={32} src={item.imageSrc} alt={`${item.type} icon`} />
                    <p className="ml-2 text-md font-bold">{item.title}</p>
                  </div>
                  <PlusIcon stroke={theme === 'light' ? '#155EEF' : '#fff'} />
                </div>
              </Link>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

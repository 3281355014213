import { atom } from 'recoil';

export const leftSidepanelOpenState = atom<boolean>({
  key: 'leftSidepanelOpenState',
  default: true,
});

export const rightSidepanelOpenState = atom<boolean>({
  key: 'rightSidepanelOpenState',
  default: true,
});

import { EngageType, ReportType, UserType } from '@src/client/lib/api/types/response';

export const ReportTypes = [ReportType.INSIGHT, ReportType.FUNNEL, ReportType.FLOW, ReportType.RETENTION];

export const EngageTypes = [EngageType.CAMPAIGN, EngageType.FLEXICAST];

export const UserTypes = [UserType.COHORTS, UserType.PROFILES];

export const ReportTypeDisplyInfo = {
  [ReportType.INSIGHT]: {
    title: 'Insights',
    path: 'insights',
    description:
      'Analyze the behavior of actions or events over time through a visual representation and detailed breakdown.',
    imageSrc: '/images/v2/insights-small.png',
    color: '#FFFAEB',
  },
  [ReportType.FUNNEL]: {
    title: 'Funnels',
    path: 'funnels',
    description: 'Analyze user journey and drop-off rates to determine action sequence completion.',
    imageSrc: '/images/v2/funnels-small.png',
    color: '#FFFBFA',
  },
  [ReportType.FLOW]: {
    title: 'Flows',
    path: 'flows',
    description: 'View entire user journey between given source and destination.',
    imageSrc: '/images/v2/flows-small.png',
    color: '#F6FEF9',
  },
  [ReportType.RETENTION]: {
    title: 'Retention',
    path: 'retention',
    description: 'Evaluate user retention by determining the frequency of returning users over time.',
    imageSrc: '/images/v2/retention-small.png',
    color: '#ECFDFF',
  },
};

export const EngageTypeDisplayInfo = {
  [EngageType.CAMPAIGN]: {
    title: 'Campaigns',
    path: 'engage/list',
    description:
      'Harness the power of user cohorts and actions to create tailored campaigns that will captivate your audience and drive conversions',
    imageSrc: '/images/v2/speaker.png',
    color: '#FFFAEB',
  },
  [EngageType.FLEXICAST]: {
    title: 'FlexiCast',
    path: 'flexicast/list',
    description:
      'Targeted customer engagement through event-triggered communications to individual or grouped users, fostering personalized interactions.',
    imageSrc: '/images/v2/flexicast.svg',
    color: '#F6FEF9',
  },
};

export const UserTypeDisplayInfo = {
  [UserType.COHORTS]: {
    title: 'Cohorts',
    path: 'cohort/list',
    description:
      'Explore, create, and update user cohorts effortlessly, presenting a concise overview of distinct groups based on shared characteristics or behaviors.',
    imageSrc: '/images/v2/cohorts.svg',
    color: '#FFF4ED',
  },
  [UserType.PROFILES]: {
    title: 'Profiles',
    path: 'user-profiles/list',
    description:
      'Provides a streamlined interface for accessing detailed user profiles and their associated events, facilitating comprehensive understanding and engagement analysis in one convenient view.',
    imageSrc: '/images/v2/user-profiles.svg',
    color: '#F0FDF9',
  },
};

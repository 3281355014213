// NOTE: Radix primittives don't support multi select right now. Using 'react-select' for now. Some other inspiration using 'cmdk' package and other radix primitives
// https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-multi-select.tsx
// https://craft.mxkaske.dev/post/fancy-multi-select

import React from 'react';
import ReactSelect, { createFilter, NoticeProps, OptionProps } from 'react-select';

import { SelectCheckIcon } from '../icons/ReportIcons';
import { Tooltip } from '../tooltip';
import { cn, HoverableClassNames } from '../utils';
import { SelectClassNames } from './types';

function CustomOption({ innerProps, data, isSelected, isDisabled, ...props }: OptionProps) {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = innerProps; // check: https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data
  return (
    <div
      {...rest}
      className={cn(
        'cursor-default select-none flex w-full justify-between items-center rounded py-1.5 px-2 mb-1  bg-transparent hover:bg-gray-100 hover:dark:bg-foreground-secondary focus:bg-gray-100 focus:dark:bg-foreground-secondary',
        isSelected ? 'bg-gray-200 dark:bg-foreground-secondary' : '',
        isDisabled ? 'cursor-not-allowed opacity-70' : '',
      )}
    >
      <div className="inline-flex items-center space-x-1 w-full">
        {(data as any).icon ? (data as any).icon : null}
        <Tooltip content={(data as any).tooltip ?? (data as any).label}>
          <p className="truncate text-sm outline-none">{(data as any).label}</p>
        </Tooltip>
      </div>
      {isSelected ? <SelectCheckIcon /> : null}
    </div>
  );
}

function NoOptionsMessage({ ...props }: NoticeProps) {
  return <p className="text-xs font-normal text-center">No options found</p>;
}

export const Select = React.forwardRef<
  React.ElementRef<typeof ReactSelect>,
  React.ComponentPropsWithoutRef<typeof ReactSelect> & { selectClassNames?: SelectClassNames }
>(
  (
    {
      isMulti,
      autoFocus,
      value,
      isLoading,
      options,
      menuIsOpen,
      placeholder,
      selectClassNames,
      onChange,
      onBlur,
      components = {},
      ...props
    },
    ref,
  ) => (
    <ReactSelect
      ref={ref}
      isMulti={isMulti}
      autoFocus={autoFocus}
      openMenuOnFocus
      hideSelectedOptions={false}
      value={value}
      isLoading={isLoading}
      onChange={onChange}
      options={options}
      unstyled
      onBlur={onBlur}
      menuIsOpen={menuIsOpen}
      placeholder={placeholder}
      components={{ Option: CustomOption, NoOptionsMessage, ...components }}
      filterOption={createFilter({ ignoreAccents: false })}
      classNames={{
        control: (_state) => cn('border border-border rounded-xl px-2', HoverableClassNames, selectClassNames?.control),
        menu: (_state) =>
          cn(
            'border border-border bg-popover text-popover-foreground shadow-md p-2 mt-2 rounded',
            selectClassNames?.menu,
          ),
        // option: (state) =>
        // cn(
        //   'cursor-default truncate select-none items-center rounded py-1.5 px-2 mb-1 text-sm outline-none bg-transparent hover:bg-primary-light hover:dark:bg-foreground-secondary focus:bg-primary-light focus:dark:bg-foreground-secondary',
        //   state.isSelected ? 'bg-primary-light dark:bg-foreground-secondary' : '',
        // ),
        singleValue: (_state) => cn('text-sm font-normal', selectClassNames?.singleValue),
        multiValue: (_state) => 'bg-white border border-border dark:border-0 rounded-xl px-2 py-0.5 select-none',
        multiValueLabel: (_state) => 'text-xs text-black font-normal bg-transparent',
        multiValueRemove: (_state) => 'text-black',
        placeholder: (_state) =>
          cn('text-sm font-normal text-foreground-secondary dark:text-foreground', selectClassNames?.placeholder ?? ''),
        indicatorsContainer: (_state) => cn('opacity-70', selectClassNames?.indicatorsContainer),
        dropdownIndicator: (_state) => cn('', selectClassNames?.dropdownIndicator),
        loadingMessage: (_state) => 'text-sm font-semibold text-foreground-secondary',
        container: (state) => cn(state.isDisabled ? 'opacity-50 !cursor-not-allowed' : '', selectClassNames?.container),
        menuList: (_state) => cn('overflow-x-hidden', selectClassNames?.menuList),
      }}
      {...props}
    />
  ),
);

Select.displayName = 'Select';

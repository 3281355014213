import { useTheme } from '@src/client/v2/ui-library/theme-provider';
import { SVGProps } from 'react';

export const DefaultNavbarIconStroke = '#D0D5DD';

export function ReportsMenuIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18.5 20V10m-6 10V4m-6 16v-6"
      />
    </svg>
  );
}

export function EventsIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21.5 10h-18m13-8v4m-8-4v4m-.2 16h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.22 4 18.38 4 16.7 4H8.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3.5 6.28 3.5 7.12 3.5 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.78 22 6.62 22 8.3 22Z"
      />
    </svg>
  );
}

export function AlertsIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15.5 19a3 3 0 1 1-6 0m4.797-12.761a2.5 2.5 0 1 0-3.593 0M3.047 8.323a4.007 4.007 0 0 1 2.032-3.52m16.874 3.52a4.007 4.007 0 0 0-2.032-3.52m-1.42 6.397c0-1.38-.633-2.702-1.758-3.677C15.618 6.548 14.092 6 12.5 6c-1.591 0-3.117.548-4.242 1.523C7.132 8.498 6.5 9.821 6.5 11.2c0 2.282-.566 3.95-1.272 5.145-.804 1.36-1.207 2.041-1.19 2.204.017.186.05.244.202.355.131.096.794.096 2.118.096h12.284c1.325 0 1.987 0 2.119-.096.15-.11.184-.17.202-.355.016-.163-.386-.843-1.19-2.204-.707-1.194-1.273-2.863-1.273-5.145Z"
      />
    </svg>
  );
}

export function EngageIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 10.5h.01m4.49 0h.01m4.49 0h.01M7.5 18v2.335c0 .533 0 .8.11.937a.5.5 0 0 0 .39.188c.176 0 .384-.167.8-.5l2.385-1.908c.487-.39.731-.585 1.002-.724.241-.122.497-.212.762-.267.299-.061.61-.061 1.235-.061H16.7c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.22 3 18.38 3 16.7 3H8.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3.5 5.28 3.5 6.12 3.5 7.8V14c0 .93 0 1.395.102 1.777a3 3 0 0 0 2.122 2.12C6.105 18 6.57 18 7.5 18Zm1-7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      />
    </svg>
  );
}

export function UsersIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16.5 3.468a4.5 4.5 0 0 1 0 8.064m2 5.234c1.512.684 2.872 1.799 4 3.234m-20 0c1.946-2.477 4.59-4 7.5-4s5.553 1.523 7.5 4m-3-12.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
      />
    </svg>
  );
}

export function TeamIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" width={24} height={24} {...props}>
      <path
        d="M16.5 15.75V14.25C16.5 12.8521 15.5439 11.6775 14.25 11.3445M11.625 2.46807C12.7244 2.91311 13.5 3.99098 13.5 5.25C13.5 6.50902 12.7244 7.58689 11.625 8.03193M12.75 15.75C12.75 14.3522 12.75 13.6533 12.5216 13.1019C12.2172 12.3669 11.6331 11.7828 10.8981 11.4784C10.3467 11.25 9.64783 11.25 8.25 11.25H6C4.60218 11.25 3.90326 11.25 3.35195 11.4784C2.61687 11.7828 2.03284 12.3669 1.72836 13.1019C1.5 13.6533 1.5 14.3522 1.5 15.75M10.125 5.25C10.125 6.90685 8.78185 8.25 7.125 8.25C5.46815 8.25 4.125 6.90685 4.125 5.25C4.125 3.59315 5.46815 2.25 7.125 2.25C8.78185 2.25 10.125 3.59315 10.125 5.25Z"
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TeamAddIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 15.75V14.25C16.5 12.8521 15.5439 11.6775 14.25 11.3445M11.625 2.46807C12.7244 2.91311 13.5 3.99098 13.5 5.25C13.5 6.50902 12.7244 7.58689 11.625 8.03193M12.75 15.75C12.75 14.3522 12.75 13.6533 12.5216 13.1019C12.2172 12.3669 11.6331 11.7828 10.8981 11.4784C10.3467 11.25 9.64783 11.25 8.25 11.25H6C4.60218 11.25 3.90326 11.25 3.35195 11.4784C2.61687 11.7828 2.03284 12.3669 1.72836 13.1019C1.5 13.6533 1.5 14.3522 1.5 15.75M10.125 5.25C10.125 6.90685 8.78185 8.25 7.125 8.25C5.46815 8.25 4.125 6.90685 4.125 5.25C4.125 3.59315 5.46815 2.25 7.125 2.25C8.78185 2.25 10.125 3.59315 10.125 5.25Z M19 21.5V15.5M16 18.5H22"
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchIcon({ stroke, ...props }: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <path
        stroke={stroke || (theme === 'light' ? '#475467' : '#ffffff')}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="m21 21-3.5-3.5m2.5-6a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z"
      />
    </svg>
  );
}

export function SettingsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} {...props}>
      <g stroke="#EAECF0" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.667} clipPath="url(#a)">
        <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
        <path d="M15.606 12.273a1.25 1.25 0 0 0 .25 1.379l.046.045a1.516 1.516 0 1 1-2.144 2.144l-.046-.045a1.25 1.25 0 0 0-1.379-.25 1.25 1.25 0 0 0-.757 1.143v.13a1.515 1.515 0 1 1-3.03 0v-.069a1.25 1.25 0 0 0-.819-1.144 1.25 1.25 0 0 0-1.378.25l-.046.046a1.516 1.516 0 1 1-2.144-2.144l.046-.046a1.25 1.25 0 0 0 .25-1.379 1.25 1.25 0 0 0-1.144-.757h-.13a1.515 1.515 0 1 1 0-3.03h.07a1.25 1.25 0 0 0 1.143-.819 1.25 1.25 0 0 0-.25-1.378l-.045-.046A1.515 1.515 0 1 1 6.243 4.16l.045.046a1.25 1.25 0 0 0 1.379.25h.06a1.25 1.25 0 0 0 .758-1.144v-.13a1.515 1.515 0 1 1 3.03 0v.07a1.25 1.25 0 0 0 .758 1.143 1.25 1.25 0 0 0 1.379-.25l.045-.045a1.515 1.515 0 1 1 2.144 2.144l-.045.045a1.25 1.25 0 0 0-.25 1.379v.06a1.25 1.25 0 0 0 1.143.758h.13a1.515 1.515 0 1 1 0 3.03h-.069a1.25 1.25 0 0 0-1.144.758Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LogoutIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={16} height={16} {...props}>
      <path
        stroke={theme === 'light' ? '#475467' : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
        d="M10.667 11.333 14 8m0 0-3.333-3.333M14 8H6m0-6h-.8c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 3.52 2 4.08 2 5.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 14 4.08 14 5.2 14H6"
      />
    </svg>
  );
}

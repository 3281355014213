/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { Link } from 'react-router-dom';

import NavbarMenu from './NavbarMenu';
import NavbarSearch from './NavbarSearch';
import SettingsMenu from './SettingsMenu';
import WorkspaceSelector from './WorkspaceSelector';

function PerceptLogo() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <Link to={getLinkWithWorkspace('home')} className="mr-4 flex">
      <img alt="percept-logo" width={112} src="/images/pi_logo_white.svg" />
    </Link>
  );
}

export default function SiteNavbar() {
  return (
    <header className="flex fixed top-0 left-0 right-0 h-navbar bg-neutral px-4 z-[1000]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start">
          <PerceptLogo />
          <NavbarMenu />
        </div>
        <div className="flex items-center justify-start">
          <NavbarSearch />
          <SettingsMenu />
          <WorkspaceSelector />
        </div>
      </div>
    </header>
  );
}

/* eslint-disable class-methods-use-this */
import Percept from '@perceptinsight/percept-js';

import { UserInfo } from '../api/types/response';
import { ErrorTags, EventNames, EventProperty } from './events';

const { VITE_PERCEPT_KEY, VITE_UDAAN_ENV } = import.meta.env;

const ENVS_TO_IGNORE = ['local', 'dev'];

const USER_PROPS_TO_TRACK = [
  EventProperty.Email,
  EventProperty.FirstName,
  EventProperty.LastName,
  EventProperty.PrivilegeLevel,
  EventProperty.RoleName,
];
class TrackerClass {
  isInitialized = false;

  activeUserData: UserInfo | undefined;

  currentScreentName = '';

  init() {
    if (this.isInitialized) return;
    if (ENVS_TO_IGNORE.includes(VITE_UDAAN_ENV)) return;
    Percept.init(VITE_PERCEPT_KEY, {
      autoTrackApiCalls: true,
      apiTrackingBlocklist: 'login.microsoftonline.com',
    });
    this.isInitialized = true;
    this.trackEvent(EventNames.APP_OPEN);
  }

  setUser(userId: string) {
    if (!this.isInitialized) return;
    Percept.setUserId(userId);
  }

  setUserInfo(userInfo: UserInfo) {
    if (!this.isInitialized) return;
    this.activeUserData = userInfo;
    this.setUser(userInfo.userId);
    Object.keys(userInfo)
      .filter((k) => USER_PROPS_TO_TRACK.includes(k as any))
      .forEach((key) => {
        const value = userInfo[key as keyof UserInfo]?.toString();
        this.setGlobalProperty(key as unknown as EventProperty, value);
      });
  }

  setCurrentScreenName(screenName: string) {
    this.currentScreentName = screenName;
  }

  getCurrentScreenName() {
    return this.currentScreentName;
  }

  setGlobalProperty(propertyKey: EventProperty, value: string) {
    if (!this.isInitialized) return;
    Percept.setGlobalProperty(propertyKey as unknown as string, value);
  }

  trackError(error: Error, tag: ErrorTags, extraProps?: Record<string, unknown>) {
    this.trackEvent(
      EventNames.ERROR,
      {
        [EventProperty.ErrorStack]: error?.stack?.toString(),
        [EventProperty.ErrorMessage]: error?.message,
        [EventProperty.ErrorTag]: tag,
      },
      extraProps,
    );
  }

  trackEvent(
    eventName: EventNames,
    properties?: Partial<Record<EventProperty, unknown>>,
    untypedProps?: Record<string, unknown>,
  ) {
    if (!this.isInitialized) return;
    const propsToCapture = {
      ...(properties ?? {}),
      ...(untypedProps ?? {}),
      screen: this.currentScreentName,
      path: location.pathname, // eslint-disable-line  no-restricted-globals
      env: VITE_UDAAN_ENV,
    };
    Percept.capture(eventName, {
      ...propsToCapture,
    });
  }

  logout() {
    if (!this.isInitialized) return;
    this.trackEvent(EventNames.LOGOUT);
    Percept.clear();
  }
}

const Tracker = new TrackerClass();

export default Tracker;

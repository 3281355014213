import { userInfoState } from '@src/client/recoil/atoms';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
  }, []);

  useEffect(() => {
    if (ref?.current) observerRef?.current?.observe(ref.current);

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function useIsFlowDisabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('udaan');
}

export function useCustomLineChart() {
  const userInfo = useRecoilValue(userInfoState);
  return (
    userInfo?.activeWorkspaceId &&
    ['perceptpixel', 'percept-webapp', 'udaan-prod'].includes(userInfo?.activeWorkspaceId)
  );
}

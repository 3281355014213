import { atom } from 'recoil';

export enum ListViewType {
  ListView = 'ListView',
  GridView = 'GridView',
}

export const reportsListViewState = atom<ListViewType>({
  key: 'reportsListViewState',
  default: ListViewType.ListView,
});

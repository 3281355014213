import { googleLogout } from '@react-oauth/google';
import { Cookie } from '@src/client/lib/api/constants';
import { signup, validateAndLogin } from '@src/client/lib/api/mutations/common';
import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ErrorTags } from '../lib/analytics/events';
import Tracker from '../lib/analytics/tracker';
import { OauthProvider } from '../lib/api/types/request';
import countries from '../lib/country.json';
import { clearActiveWorkspaceDetailsFromLocalStorage } from '../lib/utils';
import { ScreenNames } from '../routes/data';
import LoginBg from '../v2/components/login-bg';

function Welcome() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([Cookie.refresh_token, Cookie.id_token, Cookie.auth_type]);
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const email = searchParams.get('email');
  const name = searchParams.get('name') ? searchParams.get('name') : email;
  const profileUrl = searchParams.get('profileUrl') ? searchParams.get('profileUrl') : null;

  useEffect(() => {
    Tracker.setCurrentScreenName(ScreenNames.WelcomeScreen);
  }, []);

  const authorize = () => {
    validateAndLogin()
      .then(async (_r) => {
        await queryClient.invalidateQueries('userInfo');
        setTimeout(() => {
          navigate('/');
        }, 200);
      })
      .catch((e) => {
        Tracker.trackError(e, ErrorTags.AUTHORIZE_ERROR);
        Tracker.logout();
        if (cookies[Cookie.auth_type] === OauthProvider.google) {
          googleLogout();
        }
        clearActiveWorkspaceDetailsFromLocalStorage();
        removeCookie(Cookie.id_token, {
          path: '/',
        });
        removeCookie(Cookie.refresh_token, {
          path: '/',
        });
        removeCookie(Cookie.auth_type, {
          path: '/',
        });
        queryClient.invalidateQueries();
      });
  };

  const autoSignup = () => {
    const { organization, department, contact, prefix } = form.getFieldsValue();
    signup(name!, email!, profileUrl, organization, department, `${prefix}${contact}`).then(async (_r: any) => {
      sessionStorage.removeItem('first_time_user');
      authorize();
    });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select showSearch optionFilterProp="label" style={{ width: 80 }} popupMatchSelectWidth={false}>
        {countries.map((country) => (
          <Select.Option key={country.label} value={country.value} label={country.label}>
            +{country.value}({country.label})
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <LoginBg>
      <div className="mt-4 flex items-center justify-center flex-col p-4">
        <div style={{ width: '550px' }} className="mt-6 px-4 py-6 bg-white rounded-2xl">
          <div className="flex flex-col items-center w-[340px] px-2 m-auto">
            <p className="font-bold text-black text-h4 text-center mt-4">👋🏻&nbsp;Welcome</p>
            <Form form={form} name="welcome" initialValues={{ prefix: '91' }} onFinish={autoSignup} className="mt-4">
              <Form.Item name="organization" rules={[{ required: true, message: 'Please input your company name!' }]}>
                <Input placeholder="Who do you work for?" size="large" />
              </Form.Item>
              <Form.Item
                name="department"
                rules={[{ required: true, message: 'Please select your department!' }]}
                tooltip="Your job role"
              >
                <Select placeholder="What kind of work do you do?" size="large">
                  <Select.Option value="engineering">Engineering</Select.Option>
                  <Select.Option value="sales_marketing">Sales and Marketing</Select.Option>
                  <Select.Option value="founder_cxo">Founder/CXO</Select.Option>
                  <Select.Option value="analytics_datascience">Analytics & Data Science</Select.Option>
                  <Select.Option value="design">Design</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="contact"
                rules={[
                  { required: true, message: 'Please input your mobile number!' },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: 'Please enter a valid 10-digit phone number!',
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: '100%' }}
                  placeholder="How can we reach you?"
                  size="large"
                />
              </Form.Item>
              <Form.Item className="mt-4 text-center">
                <Button
                  type="primary"
                  className="text-center"
                  block
                  htmlType="submit"
                  style={{ borderRadius: '12px', backgroundColor: '#155EEF' }}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
          <p className="text-center text-xs mt-10">
            We will create a demo account so you can try out all of our features and see if it is a good fit for you.
            You will have full access to all of our features for a limited time. Please explore and reach out to us at{' '}
            <a href="mailto:support@perceptinsight.com" className="text-blue-600">
              support@perceptinsight.com
            </a>{' '}
            if you have any questions.
          </p>
        </div>
      </div>
    </LoginBg>
  );
}

export default Welcome;

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { updateLastActiveWorkspaceId } from '@src/client/lib/api/mutations/common';
import { Workspace } from '@src/client/lib/api/types/response';
import { saveActiveWorkspaceDetailsInLocalStorage } from '@src/client/lib/utils';
import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@src/client/v2/ui-library/dropdown';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useToast } from '../../ui-library/toast/use-toast';
import { globalPageLoaderState } from '../global-page-loader';
import { sortWorkspaces } from './utils';

export default function WorkspaceSelector() {
  const queryClient = useQueryClient();
  const userInfo = useRecoilValue(userInfoState);
  const [activeWorkspace, setActiveWorkspace] = useRecoilState(activeWorkspaceState);
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const { toast } = useToast();

  const updateActiveWorkspaceRequest = useMutation(updateLastActiveWorkspaceId);

  const handleWorkspaceSelection = (ws: Workspace) => {
    setShowGlobalPageLoader(true);
    updateActiveWorkspaceRequest
      .mutateAsync({ lastActiveWorkspaceId: ws.id })
      .then(async (_res) => {
        setActiveWorkspace(ws);
        saveActiveWorkspaceDetailsInLocalStorage(ws.id, ws.tenantId);
        await queryClient.invalidateQueries();
        setShowGlobalPageLoader(false);
        window.location.replace(`/${ws.id}/home`);
      })
      .catch((error) => {
        setShowGlobalPageLoader(false);
        toast({
          variant: 'danger',
          title: 'Workspace switching failed',
        });
        Tracker.trackError(error, ErrorTags.ACTIVE_WORKSPACE_UPDATE_ERROR, {
          workspaceToUpdate: ws.id,
          changingFrom: 'User Settings',
        });
      });
  };

  if (!userInfo?.workspaces || !userInfo?.activeWorkspaceId) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="p-2 mr-3 flex items-center bg-neutral-dark hover:bg-neutral-dark dark:hover:bg-neutral-dark rounded-xl text-gray-300 hover:text-gray-300 data-[state=open]:text-gray-300 data-[state=open]:bg-neutral-dark dark:data-[state=open]:text-gray-300 dark:data-[state=open]:bg-neutral-dark">
        {activeWorkspace?.logoUrl ? (
          <img src={activeWorkspace.logoUrl} className="h-6 w-6" alt={`${activeWorkspace?.name} logo`} />
        ) : null}
        <div className="text-sm font-semibold mx-1">{activeWorkspace?.name}</div>
        <ChevronDownIcon className="h-6 w-6 text-gray-300" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
        {sortWorkspaces(userInfo?.workspaces ?? []).map((workspace) => (
          <DropdownMenuItem
            key={workspace.id}
            onClick={() => handleWorkspaceSelection(workspace)}
            className="cursor-pointer rounded-md "
          >
            <div className="mx-2 inline-flex items-center space-x-1">
              {workspace.logoUrl ? (
                <img src={workspace.logoUrl} className="h-6 w-6" alt={`${workspace?.name} logo`} />
              ) : null}
              <p>{workspace.name}</p>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

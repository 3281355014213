import Lottie from 'lottie-react';

import barAnimation from '../lottie-files/bar-loader.json';
import spinAnimation from '../lottie-files/spin-loader.json';

export function SpinLoader({ size = 'regular', relative = false }: { size?: 'small' | 'regular'; relative?: boolean }) {
  return (
    <Lottie
      animationData={spinAnimation}
      loop
      className={`${size === 'regular' ? 'h-12' : 'h-8'} ${
        relative ? '' : 'absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'
      }`}
    />
  );
}

export function BarLoader() {
  return (
    <Lottie
      animationData={barAnimation}
      loop
      className="h-24 absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4"
    />
  );
}

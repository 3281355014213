import { Filter } from '@src/client/v2/helpers/reports/types';
import { atom, selector, useResetRecoilState } from 'recoil';

export const globalFiltersStateV2 = atom<Filter[]>({
  key: 'globalFiltersStateV2',
  default: [],
});

export const validGlobalFiltersState = selector({
  key: 'validGlobalFiltersState',
  get: ({ get }) => {
    const filters = get(globalFiltersStateV2);

    return filters.filter((d) => d.property && d.property !== '' && d.values.length > 0);
  },
});

/* Helpers */

export const useResetGlobalFiltersStateV2 = () => {
  const resetGlobalFilters = useResetRecoilState(globalFiltersStateV2);

  return () => {
    resetGlobalFilters();
  };
};

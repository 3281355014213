import { DateRangeEnum } from '@src/client/v2/helpers/reports/constants';
import { Dayjs } from 'dayjs';
import { atom, useResetRecoilState } from 'recoil';

export const dateRangeState = atom<DateRangeEnum>({
  key: 'dateRangeState',
  default: DateRangeEnum.TODAY,
});

// ["". ""] -> tuple.. 1st value is from and 2nd value is to
export const customDateRangeState = atom<[Dayjs, Dayjs] | []>({
  key: 'customDateRangeState',
  default: [],
});

/* Helpers */
export const useResetDateRangeState = () => {
  const resetDateRangeState = useResetRecoilState(dateRangeState);
  const resetCustomDateRange = useResetRecoilState(customDateRangeState);

  return () => {
    resetDateRangeState();
    resetCustomDateRange();
  };
};

/* eslint-disable class-methods-use-this */
import {
  CLSMetricWithAttribution,
  FCPMetricWithAttribution,
  FIDMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
  Metric,
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB,
  TTFBMetricWithAttribution,
} from 'web-vitals/attribution';

import { EventNames, EventProperty } from './events';
import Tracker from './tracker';

class WebVitalsT {
  init = () => {
    onCLS(this.trackCLS);
    onFID(this.trackFID);
    onLCP(this.trackLCP);
    onTTFB(this.trackTTFB);
    onFCP(this.trackFCP);
    onINP(this.trackINP);
  };

  trackCLS = (data: CLSMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    eventProps[EventProperty.MetricDebugTarget] = attribution?.largestShiftTarget?.toString();
    // TODO @manish: remove duplicate events passing after analysing the use case
    Tracker.trackEvent(EventNames.CLS, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  trackFID = (data: FIDMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    eventProps[EventProperty.MetricDebugTarget] = attribution?.eventTarget?.toString();
    Tracker.trackEvent(EventNames.FID, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  trackLCP = (data: LCPMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    eventProps[EventProperty.MetricDebugTarget] = attribution?.element?.toString();
    Tracker.trackEvent(EventNames.LCP, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  trackTTFB = (data: TTFBMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    Tracker.trackEvent(EventNames.TTFB, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  trackFCP = (data: FCPMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    Tracker.trackEvent(EventNames.FCP, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  trackINP = (data: INPMetricWithAttribution) => {
    const { attribution, ...metric } = data;
    const eventProps = this.getGenericEventProps(metric);
    eventProps[EventProperty.MetricDebugTarget] = attribution?.eventTarget?.toString();
    Tracker.trackEvent(EventNames.INP, eventProps);
    Tracker.trackEvent(EventNames.WEB_VITALS, eventProps);
  };

  getGenericEventProps = (metric: Metric): Partial<Record<EventProperty, string>> => ({
    [EventProperty.MetricId]: metric.id,
    [EventProperty.MetricName]: metric.name,
    [EventProperty.MetricValue]: metric.value.toString(),
    [EventProperty.MetricRating]: metric.rating,
    [EventProperty.MetricNavigationType]: metric.navigationType,
  });
}

const WebVitalsTracker = new WebVitalsT();

export default WebVitalsTracker;

import { GridIcon, ListIcon } from '@src/client/v2/ui-library/icons/HomepageIcons';
import { useRecoilState } from 'recoil';

import { ListViewType, reportsListViewState } from '../state';

export default function ListViewToggler() {
  const [viewState, setViewState] = useRecoilState(reportsListViewState);

  return (
    <div className="inline-flex space-x-2 p-1 rounded-xl bg-gray-200 dark:bg-[#0D121C]">
      <div
        className={`hover:bg-white dark:hover:bg-background p-1 rounded-lg cursor-pointer ${
          viewState === ListViewType.ListView ? 'bg-white dark:bg-background' : ''
        }`}
        role="button"
        tabIndex={0}
        aria-label="Switch to list view"
        onClick={() => setViewState(ListViewType.ListView)}
        onKeyDown={() => setViewState(ListViewType.ListView)}
      >
        <ListIcon />
      </div>
      <div
        className={`hover:bg-white dark:hover:bg-background p-1 rounded-lg cursor-pointer ${
          viewState === ListViewType.GridView ? 'bg-white dark:bg-background' : ''
        }`}
        role="button"
        tabIndex={0}
        aria-label="Switch to grid view"
        onClick={() => setViewState(ListViewType.GridView)}
        onKeyDown={() => setViewState(ListViewType.GridView)}
      >
        <GridIcon />
      </div>
    </div>
  );
}

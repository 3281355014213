import { Button } from '@src/client/v2/ui-library/button';
import { SidePanelCollapseIcon } from '@src/client/v2/ui-library/icons/HomepageIcons';
import { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';

import { cn } from '../../ui-library/utils';
import { leftSidepanelOpenState, rightSidepanelOpenState } from './state';

interface LeftSidepanelProps extends PropsWithChildren {
  size?: 'small' | 'large';
  classname?: string;
}
export function LeftSidepanel({ children, size = 'small', classname = '' }: LeftSidepanelProps) {
  const [isSidepanelOpen, setIsSidepanelOpen] = useRecoilState(leftSidepanelOpenState);
  return (
    <div
      className={cn(
        `${
          isSidepanelOpen ? (size === 'small' ? 'w-[260px]' : 'w-[25%] max-w-[340px] min-w-[320px]') : 'w-[60px]'
        } sticky z-[11] bg-background left-0 top-navbar h-layout-v2  max-h-layout-v2 overflow-y-scroll border-r-border border-r pt-6 px-2 flex flex-col ease-in-out duration-300 no-scrollbar`,
        classname,
      )}
    >
      <div className="flex-1 overflow-y-scroll pb-12 no-scrollbar">{children}</div>
      <div className="text-right">
        <Button variant="icon" onClick={() => setIsSidepanelOpen(!isSidepanelOpen)} className="shadow-lg">
          <SidePanelCollapseIcon className={isSidepanelOpen ? '' : 'rotate-180'} />
        </Button>
      </div>
    </div>
  );
}

export function RightSidepanel({ children }: PropsWithChildren) {
  const [isSidepanelOpen, setIsSidepanelOpen] = useRecoilState(rightSidepanelOpenState);
  return (
    <div
      className={`${
        isSidepanelOpen ? 'w-[260px] pl-4 pr-2' : 'w-[40px] px-2'
      } sticky right-0 top-navbar h-layout-v2 max-h-layout-v2 overflow-y-scroll border-l-border border-l pt-6 flex flex-col ease-in-out duration-300 no-scrollbar`}
    >
      <div className="flex-1 overflow-y-scroll pb-12 no-scrollbar">{children}</div>
      <div className="text-left">
        <Button variant="icon" onClick={() => setIsSidepanelOpen(!isSidepanelOpen)} className="shadow-lg">
          <SidePanelCollapseIcon className={isSidepanelOpen ? 'rotate-180' : ''} />
        </Button>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/alt-text */
import {
  BuildBetterIconWithText,
  PerceptInsightWhiteIconWithText,
} from '@src/client/v2/ui-library/icons/HomepageIcons';
import { Fragment, PropsWithChildren } from 'react';

function AnimatedLines() {
  return (
    <>
      {/* bottom left and right animations */}
      {/* <div className="absolute delay-500 duration-8000 animate-loginPageLineAnimationSlideRight linear bottom-[170px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div> */}
      <div className="absolute delay-100 duration-10000 animate-loginPageLineAnimationSlideLeft linear rotate-180 bottom-[360px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div>
      <div className="absolute duration-8000 animate-loginPageLineAnimationSlideRight linear bottom-[460px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div>
      {/* left bottom up and down animations */}
      <div className="absolute delay-400 duration-8000 animate-loginPageLineAnimationSlideDown linear -rotate-90 left-[420px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div>
      {/* <div className="absolute delay-100 duration-3000 animate-loginPageLineAnimationSlideUp linear rotate-90 left-[130px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div> */}
      {/* right bottom up and down animations */}
      <div className="absolute duration-8000 animate-loginPageLineAnimationSlideUp linear rotate-90 right-[420px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div>
      {/* <div className="absolute delay-100 duration-3000 animate-loginPageLineAnimationSlideDown linear -rotate-90 right-[130px]">
        <img src="/images/v2/login-animation-line.svg" />
      </div> */}
    </>
  );
}

export default function LoginBg({ children }: PropsWithChildren) {
  return (
    <div
      className="min-h-screen relative w-full flex flex-col overflow-hidden"
      style={{ backgroundImage: 'url(/images/v2/login-bg-image.png)' }}
    >
      <AnimatedLines />
      <div className="flex top-[10px] flex-col items-center mt-[100px] relative">
        {/* top left and right animations */}
        <div className=" absolute delay-100 duration-10000 animate-loginPageLineAnimationSlideRight linear">
          <img src="/images/v2/login-animation-line.svg" />
        </div>
        <div className=" absolute top-[100px] delay-500 duration-8000 animate-loginPageLineAnimationSlideLeft rotate-180 linear">
          <img src="/images/v2/login-animation-line.svg" />
        </div>
        <PerceptInsightWhiteIconWithText />
        <BuildBetterIconWithText />
        {children}
      </div>
    </div>
  );
}

import { LockOutlined } from '@ant-design/icons';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { UserType } from '@src/client/lib/api/types/response';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { isDemoUrl } from '@src/client/lib/utils';
import { userInfoState } from '@src/client/recoil/atoms';
import {
  EngageTypeDisplayInfo,
  EngageTypes,
  ReportTypeDisplyInfo,
  ReportTypes,
  UserTypeDisplayInfo,
  UserTypes,
} from '@src/client/v2/helpers/constants';
import {
  AlertsIcon,
  DefaultNavbarIconStroke,
  EngageIcon,
  EventsIcon,
  ReportsMenuIcon,
  UsersIcon,
} from '@src/client/v2/ui-library/icons/NavbarIcons';
import { NavMenu } from '@src/client/v2/ui-library/navigation-menu';
import { navigationMenuTriggerStyle } from '@src/client/v2/ui-library/navigation-menu/NavbarPrimitives';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useIsFlowDisabled } from '../../helpers/hooks';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../../ui-library/dropdown';
import { Tooltip } from '../../ui-library/tooltip';
import { ELEVATED_ACCESS_ROLES, USER_PROFILE_NOT_ALLOWED_TENANT } from './constants';

export default function NavbarMenu() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const [userInfo] = useRecoilState(userInfoState);
  const hideFlow = useIsFlowDisabled();

  const navItems = useMemo(
    () => [
      {
        key: '1',
        item: (
          <>
            {' '}
            <ReportsMenuIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
            Reports
          </>
        ),
        child: (
          <ul className="flex flex-col space-y-2.5 p-3">
            {ReportTypes.map((reportType) => {
              const data = ReportTypeDisplyInfo[reportType];
              if (data.title === 'Flows' && hideFlow) return null;
              return (
                <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                  <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                    <div className="flex items-center mb-3">
                      <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                      <p className="text-md font-semibold text-gray-950">{data.title}</p>
                    </div>
                    <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                    <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                  </div>
                </Link>
              );
            })}
          </ul>
        ),
      },
      {
        key: '2',
        item:
          !isDemoUrl() && userInfo && !USER_PROFILE_NOT_ALLOWED_TENANT.includes(userInfo.activeTenantId) ? (
            <DropdownMenu>
              <DropdownMenuTrigger className="text-white hover:text-white dark:hover:text-white hover:rounded-md-2 hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[active]:text-white data-[active]:bg-neutral-dark/50 dark:data-[state=open]:bg-neutral-dark/50 data-[state=open]:bg-neutral-dark/50 data-[state=open]:text-white">
                <UsersIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
                <span className="mr-1">User</span>
                <ChevronDownIcon className="h-3 w-3 text-gray-300" />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
                <ul className="flex flex-col space-y-2.5 p-3">
                  {UserTypes.map((userType) => {
                    const data = UserTypeDisplayInfo[userType];
                    return (
                      <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                        <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                          <div className="flex items-center mb-3">
                            <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                            <p className="text-md font-semibold text-gray-950">{data.title}</p>
                          </div>
                          <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                          {userType === UserType.COHORTS ? (
                            <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                          ) : null}
                        </div>
                      </Link>
                    );
                  })}
                </ul>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Link to={getLinkWithWorkspace('cohort/list')} className={navigationMenuTriggerStyle()}>
              <UsersIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
              Cohorts
            </Link>
          ),
      },
      {
        key: '3',
        item: isDemoUrl() ? (
          <Tooltip content="Reach out to Percept Insight team for a demo of this feature">
            <div className={navigationMenuTriggerStyle()}>
              <EngageIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
              <span className="mr-1">Engage</span>
              <LockOutlined />
            </div>
          </Tooltip>
        ) : userInfo && ELEVATED_ACCESS_ROLES.includes(userInfo.roleName) ? (
          <DropdownMenu>
            <DropdownMenuTrigger className="text-white hover:text-white dark:hover:text-white hover:rounded-md-2 hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[active]:text-white data-[active]:bg-neutral-dark/50 dark:data-[state=open]:bg-neutral-dark/50 data-[state=open]:bg-neutral-dark/50 data-[state=open]:text-white">
              <EngageIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
              <span className="mr-1">Engage</span>
              <ChevronDownIcon className="h-3 w-3 text-gray-300" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
              <ul className="flex flex-col space-y-2.5 p-3">
                {EngageTypes.map((engageType) => {
                  const data = EngageTypeDisplayInfo[engageType];
                  return (
                    <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                      <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                        <div className="flex items-center mb-3">
                          <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                          <p className="text-md font-semibold text-gray-950">{data.title}</p>
                        </div>
                        <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                        <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null,
      },
      {
        key: '4',
        item: (
          <Link to={getLinkWithWorkspace('events')} className={navigationMenuTriggerStyle()}>
            <EventsIcon stroke={DefaultNavbarIconStroke} className="mr-2" />
            Events
          </Link>
        ),
      },
      {
        key: '5',
        item: isDemoUrl() ? (
          <Tooltip content="Reach out to Percept Insight team for a demo of this feature">
            <div className={navigationMenuTriggerStyle()}>
              <AlertsIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
              <span className="mr-1">Alerts</span>
              <LockOutlined />
            </div>
          </Tooltip>
        ) : (
          <Link to={getLinkWithWorkspace('alert/list')} className={navigationMenuTriggerStyle()}>
            <AlertsIcon className="mr-2" stroke={DefaultNavbarIconStroke} />
            Alerts
          </Link>
        ),
      },
    ],
    [userInfo], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <NavMenu navbarItems={navItems} />;
}

import { useCallback, useState } from 'react';

import { SearchIcon } from '../../ui-library/icons/NavbarIcons';
import GlobalSearchModal from './SearchModal';

export default function NavbarSearch() {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const openModal = useCallback(() => setShowSearchModal(true), []);
  const closeModal = useCallback(() => setShowSearchModal(false), []);

  return (
    <>
      <button
        type="button"
        className="p-2 flex items-center bg-neutral-dark w-[250px] rounded-xl text-gray-300 hover:ring-1"
        onClick={openModal}
      >
        <SearchIcon className="mr-2" stroke="#667085" />
        <p className="text-md font-semibold">Search</p>
      </button>
      {showSearchModal ? <GlobalSearchModal open={showSearchModal} onClose={closeModal} /> : null}
    </>
  );
}

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '../utils';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactElement<any>;
  contentClassname?: string;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: 'top' | 'right' | 'bottom' | 'left';
  delayDuration?: number;
}
export function Tooltip({
  children,
  content,
  contentClassname,
  side = 'top',
  delayDuration = 500,
  // open,
  // defaultOpen,
  // onOpenChange,
  ...props
}: TooltipProps) {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          side={side}
          align="center"
          className={cn(
            'z-50 bg-foreground max-w-200 break-words text-background rounded-xl px-3 py-1 text-xs animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            contentClassname,
          )}
          {...props}
        >
          {content}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

/* eslint-disable import/prefer-default-export */
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const ActiveClassNames =
  'bg-gray-100 text-foreground dark:text-foreground-light dark:text-foreground dark:bg-foreground-secondary';

export const HoverableClassNames =
  'bg-transparent hover:bg-gray-100 hover:text-foreground dark:text-foreground-light dark:hover:text-foreground dark:hover:bg-foreground-secondary';

export const HoverableClassNamesWithActiveState =
  'bg-transparent hover:bg-gray-100 hover:text-foreground dark:text-foreground-light dark:hover:text-foreground dark:hover:bg-foreground-secondary data-[state=open]:bg-gray-200 data-[state=open]:text-foreground  dark:data-[state=open]:text-foreground dark:data-[state=open]:bg-foreground-secondary';

export const HoverableClassNamesWithOnState =
  'bg-transparent hover:bg-gray-100 hover:text-foreground dark:text-foreground-light dark:hover:text-foreground dark:hover:bg-foreground-secondary data-[state=on]:bg-gray-200 data-[state=on]:text-foreground dark:data-[state=on]:text-foreground dark:data-[state=on]:bg-foreground-secondary';

import * as qs from 'querystringify';

import ApiClient from './client';
import { APIEndpoints } from './constants';
import { EmptyRequestParams } from './types/request';

export const UserGovService = new ApiClient('/api/governance');
export const PlatformService = new ApiClient('/api/metadata');

export const fetchAPIResponse = (
  url: APIEndpoints,
  params: EmptyRequestParams = {},
  method = 'POST',
  API: ApiClient = PlatformService,
) =>
  method === 'POST' || method === 'PATCH' || method === 'PUT' || method === 'DELETE'
    ? API.createRequest({
        path: url,
        request: {
          method,
          body: JSON.stringify(params),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        response: (r: any) => (typeof r === 'string' ? JSON.parse(r) : r),
      })({})
    : API.createRequest({
        path: url,
        request: {
          method: 'GET',
        },
        response: (r: any) => (typeof r === 'string' ? JSON.parse(r) : r),
      })({});

function isObjLiteral(_obj: any) {
  let test = _obj;
  return typeof _obj !== 'object' || _obj === null
    ? false
    : (function () {
        while (true) {
          // eslint-disable-next-line no-cond-assign
          if (Object.getPrototypeOf((test = Object.getPrototypeOf(test))) === null) {
            break;
          }
        }
        return Object.getPrototypeOf(_obj) === test;
      })();
}

export const fetchEndpoint = async (
  url: APIEndpoints,
  pathParams: any = {},
  queryParams: any = {},
  bodyParams: any = {},
  method = 'POST',
  API: ApiClient = PlatformService,
) => {
  Object.keys(pathParams).forEach((key: string, index) => {
    // eslint-disable-next-line no-param-reassign
    url = url.replace(`{${key}}`, pathParams[key]) as any;
  });
  const queryString = qs.stringify(queryParams, true);

  url = (url + queryString) as any; // eslint-disable-line no-param-reassign

  const data = await fetchAPIResponse(url, bodyParams, method, API);
  if ((data as unknown as any).code === 403) {
    throw new Error((data as unknown as any).message);
  }

  return data;
};

export const nextKey = () => Math.ceil(Math.random() * 100000).toString();

export const getUseQueryOptions = (retry: boolean | number = 0, staleTime: number = 60 * 1000, enabled = true) => ({
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime,
  retry,
  enabled,
  fetchPolicy: 'no-cache',
});

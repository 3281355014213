import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

import { Cookie } from '../lib/api/constants';
import { setLastVisitedpath } from '../lib/utils';

function ProtectedRoute({ children }: any) {
  const [cookies] = useCookies([Cookie.id_token, Cookie.refresh_token]);

  const isRouteAccesible = !!(cookies[Cookie.id_token] || cookies[Cookie.refresh_token]);
  // const isRouteAccesible = true

  if (!isRouteAccesible) {
    setLastVisitedpath();
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return children;
}

export default ProtectedRoute;

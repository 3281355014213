/* eslint-disable import/prefer-default-export */
import MyReportsScreen from '@src/client/v2/modules/home/MyReports';
import React from 'react';

import { RotuesInfo } from './types';

export enum ScreenNames {
  HomeScreen = 'HomeScreen',
  InsightsScreen = 'InsightsScreen',
  InsightsDashboard = 'InsightsDashboard',
  CohortDashboard = 'CohortDashboard',
  DashboardScreen = 'DashboardScreen',
  DashboardList = 'DashboardList',
  FunnelsScreen = 'FunnelsScreen',
  FunnelsDashboard = 'FunnelsDashboard',
  CustomEventsDashboard = 'CustomEventsDashboard',
  EventsScreen = 'EventsScreen',
  LoginScreen = 'LoginScreen',
  WelcomeScreen = 'WelcomeScreen',
  ErrorBoundaryFallbackScreen = 'ErrorBoundaryFallbackScreen',
  EngageDashboard = 'EngageDashboard',
  CreateCampaign = 'CreateCampaign',
  CreateAlert = 'CreateAlert',
  AlertList = 'AlertsList',
  AlertsDashboard = 'AlertsDashboard',
  AlertAnalysisDashboard = 'AlertAnalysisDashboard',
  EventsInfoScreen = 'EventsInfoScreen',
  EventPropertiesScreen = 'EventPropertiesScreen',
  TeamsDashboard = 'TeamsDashboard',
  FlowsDashboard = 'FlowsDashboard',
  FlowsScreen = 'FlowsScreen',
  YourReportsScreen = 'YourReportsScreen',
  NotFoundScreen = 'NotFoundScreen',
  CreateCohort = 'CreateCohort',
  FlexicastDashboard = 'FlexicastDashboard',
  CreateFlexicast = 'CreateFlexicast',
  GlobalLimitsDashboard = 'GlobalLimitsDashboard',
  RetentionScreen = 'RetentionScreen',
  UserProfilesScreen = 'UserProfilesScreen',
  UserProfileEventDashboard = 'UserProfileEventDashboard',
}

// const InsightsScreen = React.lazy(() => import('@src/client/pages/InsightsScreen'));
const InsightsScreen = React.lazy(() => import('@src/client/v2/modules/insights/InsightsScreen'));
const InsightsDashboard = React.lazy(() => import('@src/client/pages/InsightsDashboard'));
const CohortDashboard = React.lazy(() => import('@src/client/v2/modules/cohorts/CohortListPage'));
const CreateCohortScreen = React.lazy(() => import('@src/client/v2/modules/cohorts/CreateCohortScreen'));
const DashboardScreen = React.lazy(() => import('@src/client/v2/modules/dashboard'));
// const FunnelsScreen = React.lazy(() => import('@src/client/pages/FunnelsScreen'));
const FunnelsScreen = React.lazy(() => import('@src/client/v2/modules/funnels/FunnelsScreen'));
const FunnelsDashboard = React.lazy(() => import('@src/client/pages/FunnelsDashboard'));
const CustomEventsDashboard = React.lazy(() => import('@src/client/v2/modules/custom-events/CustomEventsPage'));
const GlobalLimitsDashboard = React.lazy(() => import('@src/client/v2/modules/global-limits/GlobalLimitsDashboard'));
const EventsScreen = React.lazy(() => import('@src/client/v2/modules/events'));
const EngageDashboard = React.lazy(() => import('@src/client/v2/modules/engage/campaign/CampaignListPage'));
const CreateCampaignScreen = React.lazy(() => import('@src/client/v2/modules/engage/campaign/CampaignDetailsPage'));
const AlertsDashboard = React.lazy(() => import('@src/client/v2/modules/alerts/AlertListPage'));
const UamDashboard = React.lazy(() => import('@src/client/v2/modules/uam/UamDashboard'));
const AlertAnalysisDashboard = React.lazy(() => import('@src/client/v2/modules/alerts/AlertAnalysisDashboard'));
// const FlowsScreen = React.lazy(() => import('@src/client/pages/FlowsScreen'));
const FlowsScreen = React.lazy(() => import('@src/client/v2/modules/flows/FlowsScreen'));
const FlowsDashboard = React.lazy(() => import('@src/client/pages/FlowsDashboard'));
const FlexicastDashboard = React.lazy(() => import('@src/client/v2/modules/engage/flexicast/FlexicastListPage'));
const CreateFlexicastScreen = React.lazy(() => import('@src/client/v2/modules/engage/flexicast/FlexicastDetailsPage'));
const RetentionScreen = React.lazy(() => import('@src/client/v2/modules/retention/RetentionScreen'));
const UserProfilesScreen = React.lazy(() => import('@src/client/v2/modules/user-profiles/UserProfilesScreen'));
const UserProfileEventDashboard = React.lazy(
  () => import('@src/client/v2/modules/user-profiles/UserProfileEventDashboard'),
);

export const ALL_ROUTES: RotuesInfo[] = [
  {
    title: 'Insights',
    path: 'insights',
    screenName: ScreenNames.InsightsScreen,
    component: <InsightsScreen />,
    selector: 'insights_read',
    description:
      'Analyze the behavior of actions or events\n' +
      'over time through a visual representation\n' +
      'and detailed breakdown',
    subRoutes: [
      {
        title: 'Insight List',
        path: 'list',
        component: <InsightsDashboard />,
        screenName: ScreenNames.InsightsDashboard,
        description: 'List of insights report',
        selector: 'insights_read',
      },
      {
        title: 'Insight',
        path: ':itemExtId',
        component: <InsightsScreen />,
        screenName: ScreenNames.InsightsScreen,
        description:
          'Analyze the behavior of actions or events\n' +
          'over time through a visual representation\n' +
          'and detailed breakdown',
        selector: 'insights_read',
      },
      {
        title: 'Anomaly Analysis Dashboard',
        path: ':itemExtId/anomaly/:anomalyId/dashboard',
        component: <AlertAnalysisDashboard />,
        screenName: ScreenNames.AlertAnalysisDashboard,
        description: 'Anomaly insight',
        selector: 'insights_read',
      },
    ],
  },
  {
    title: 'Funnels',
    path: 'funnels',
    screenName: ScreenNames.FunnelsScreen,
    component: <FunnelsScreen />,
    selector: 'funnels_read',
    description: 'Analyze user journey and drop-off rates to determine action sequence completion',
    subRoutes: [
      {
        title: 'Funnels',
        description: 'List of funnel report',
        path: 'list',
        component: <FunnelsDashboard />,
        screenName: ScreenNames.FunnelsDashboard,
        selector: 'funnels_read',
      },
      {
        title: 'Funnel',
        description: 'Analyze user journey and drop-off rates to determine action sequence completion',
        path: ':itemExtId',
        component: <FunnelsScreen />,
        screenName: ScreenNames.FunnelsScreen,
        selector: 'funnels_read',
      },
      {
        title: 'Anomaly Analysis Dashboard',
        path: ':itemExtId/anomaly/:anomalyId/dashboard',
        component: <AlertAnalysisDashboard />,
        screenName: ScreenNames.AlertAnalysisDashboard,
        description: 'Anomaly funnel',
        selector: 'funnels_read',
      },
    ],
  },
  {
    title: 'Cohorts',
    path: 'cohort',
    screenName: ScreenNames.CohortDashboard,
    component: <CohortDashboard />,
    selector: 'cohorts_read',
    description: 'Group individuals based on shared characteristics.',
    subRoutes: [
      {
        title: 'Cohorts',
        description: 'List of cohorts',
        path: 'list',
        component: <CohortDashboard />,
        screenName: ScreenNames.CohortDashboard,
        selector: 'cohorts_read',
      },
      {
        title: 'Create Campaign',
        path: ':itemExtId',
        component: <CreateCohortScreen />,
        screenName: ScreenNames.CreateCohort,
        description: 'Create cohorts of users based on shared characteristics.',
        selector: 'query_run',
      },
      {
        title: 'Create Campaign',
        path: 'create',
        component: <CreateCohortScreen />,
        screenName: ScreenNames.CreateCohort,
        description: 'Create cohorts of users based on shared characteristics.',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Custom Events',
    path: 'custom-events',
    screenName: ScreenNames.CustomEventsDashboard,
    component: <CustomEventsDashboard />,
    selector: 'query_run',
    description: 'Group similar events for easy analysis',
    subRoutes: [
      {
        title: 'Custom Events',
        path: 'list',
        component: <CustomEventsDashboard />,
        screenName: ScreenNames.CustomEventsDashboard,
        description: 'Group similar events for easy analysis',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Engage Settings',
    path: 'engage-settings',
    screenName: ScreenNames.GlobalLimitsDashboard,
    component: <GlobalLimitsDashboard />,
    selector: 'query_run',
    description: 'Set Global Communication Limits for Engage',
    subRoutes: [
      {
        title: 'Engage Settings',
        path: '',
        component: <GlobalLimitsDashboard />,
        screenName: ScreenNames.GlobalLimitsDashboard,
        description: 'Set Global Communication Limits for Engage',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Dashboards',
    path: 'dashboard',
    screenName: ScreenNames.DashboardScreen,
    component: <DashboardScreen />,
    selector: 'dashboards_read',
    description: 'Visualize data, standardize reporting and make decisions with dashboards',
    subRoutes: [
      {
        title: 'Dashboards',
        description: 'Visualize data, standardize reporting and make decisions with dashboards',
        path: ':itemExtId',
        component: <DashboardScreen />,
        screenName: ScreenNames.DashboardScreen,
        selector: 'dashboards_read',
      },
    ],
  },
  {
    title: 'Events',
    path: 'events',
    screenName: ScreenNames.EventsScreen,
    component: <EventsScreen />,
    selector: 'events_read',
    description: 'Easily group users action as events for efficient analysis and tracking',
    subRoutes: [
      {
        title: 'Event',
        path: ':itemExtId',
        component: <EventsScreen />,
        screenName: ScreenNames.EventsScreen,
        description: 'Easily group users action as events for efficient analysis and tracking',
        selector: 'events_read',
      },
    ],
  },
  {
    title: 'Retention',
    path: 'retention',
    screenName: ScreenNames.RetentionScreen,
    component: <RetentionScreen />,
    selector: 'events_read',
    description: 'Track user retention and monitor trends',
    subRoutes: [
      {
        title: 'Retention',
        path: ':itemExtId',
        component: <RetentionScreen />,
        screenName: ScreenNames.RetentionScreen,
        description: 'Track user retension and monitor trends',
        selector: 'events_read',
      },
    ],
  },
  {
    title: 'Engage',
    path: 'engage',
    screenName: ScreenNames.EngageDashboard,
    component: <EngageDashboard />,
    selector: 'query_run',
    description: 'Create campaigns and connect with your users',
    subRoutes: [
      {
        title: 'Engage',
        description: 'Engage campaigns list',
        path: 'list',
        component: <EngageDashboard />,
        screenName: ScreenNames.EngageDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create Campaign',
        path: ':itemExtId',
        component: <CreateCampaignScreen />,
        screenName: ScreenNames.CreateCampaign,
        description: 'Create campaigns and connect with your users',
        selector: 'query_run',
      },
      {
        title: 'Create Campaign',
        path: 'create',
        component: <CreateCampaignScreen />,
        screenName: ScreenNames.CreateCampaign,
        description: 'Create campaigns and connect with your users',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'FlexiCast',
    path: 'flexicast',
    screenName: ScreenNames.FlexicastDashboard,
    component: <FlexicastDashboard />,
    selector: 'query_run',
    description: 'Create targeted personalized communications to connect with your users',
    subRoutes: [
      {
        title: 'FlexiCast',
        description: 'Flexicast list',
        path: 'list',
        component: <FlexicastDashboard />,
        screenName: ScreenNames.FlexicastDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create FlexiCast',
        path: ':itemExtId',
        component: <CreateFlexicastScreen />,
        screenName: ScreenNames.CreateFlexicast,
        description: 'Create flexicast and connect with your users',
        selector: 'query_run',
      },
      {
        title: 'Create FlexiCast',
        path: 'create',
        component: <CreateFlexicastScreen />,
        screenName: ScreenNames.CreateFlexicast,
        description: 'Create flexicast and connect with your users',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Alerts',
    path: 'alert',
    screenName: ScreenNames.AlertList,
    component: <AlertsDashboard />,
    selector: 'query_run',
    description: 'View configured alerts',
    subRoutes: [
      {
        title: 'Alerts',
        description: 'View configured alerts',
        path: 'list',
        component: <AlertsDashboard />,
        screenName: ScreenNames.AlertList,
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Users and Teams',
    path: 'settings/manage-users',
    screenName: ScreenNames.TeamsDashboard,
    component: <UamDashboard />,
    selector: 'workspaces_read', // TODO: Update this to settings_manage
    description: 'Manage Users and Teams',
    subRoutes: [
      {
        title: 'Teams',
        path: 'teams/list',
        component: <UamDashboard />,
        screenName: ScreenNames.TeamsDashboard,
        description: 'Users and Teams list',
        selector: 'workspaces_read',
      },
      {
        title: 'Users',
        path: 'users/list',
        component: <UamDashboard />,
        screenName: ScreenNames.TeamsDashboard,
        description: 'Users and Teams list',
        selector: 'workspaces_read',
      },
    ],
  },
  {
    title: 'Flows',
    path: 'flows',
    screenName: ScreenNames.FlowsScreen,
    component: <FlowsScreen />,
    selector: 'funnels_read',
    description: 'View entire user journey between given source and destination',
    subRoutes: [
      {
        title: 'Flows',
        path: 'list',
        component: <FlowsDashboard />,
        screenName: ScreenNames.FlowsDashboard,
        description: 'List of flows report',
        selector: 'funnels_read',
      },
      {
        title: 'Flows',
        path: ':itemExtId',
        component: <FlowsScreen />,
        screenName: ScreenNames.FlowsScreen,
        selector: 'funnels_read',
        description: 'View entire user journey between given source and destination',
      },
    ],
  },
  {
    title: 'Your Reports',
    path: 'my-reports',
    screenName: ScreenNames.YourReportsScreen,
    component: <MyReportsScreen />,
    selector: 'insights_read',
    description: 'List of all your report',
  },
  {
    title: 'User Profiles',
    path: 'user-profiles',
    screenName: ScreenNames.UserProfilesScreen,
    component: <UserProfilesScreen />,
    selector: 'workspaces_read',
    description: 'View all user profiles',
    subRoutes: [
      {
        title: 'User Profiles',
        description: 'View all user profiles',
        path: 'list',
        component: <UserProfilesScreen />,
        screenName: ScreenNames.UserProfilesScreen,
        selector: 'workspaces_read',
      },
      {
        title: 'User Profile',
        path: ':userId',
        component: <UserProfileEventDashboard />,
        screenName: ScreenNames.UserProfileEventDashboard,
        description: 'View individual user profile with list of events',
        selector: 'workspaces_read',
      },
    ],
  },
];

import { googleLogout } from '@react-oauth/google';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { Cookie } from '@src/client/lib/api/constants';
import { getUserInfoOld, updateLoginTenant } from '@src/client/lib/api/queries/common';
import { OauthProvider } from '@src/client/lib/api/types/request';
import { useNavigationLinkWithWorkspace } from '@src/client/lib/hooks';
import { msalLogout } from '@src/client/lib/msal-utils';
import { clearActiveWorkspaceDetailsFromLocalStorage } from '@src/client/lib/utils';
import { userInfoState } from '@src/client/recoil/atoms';
import { Avatar, AvatarFallback, AvatarImage } from '@src/client/v2/ui-library/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@src/client/v2/ui-library/dropdown';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { SingleUserIcon } from '../../ui-library/icons/DashboardIcons';
import { EngageIcon, LogoutIcon, SettingsIcon } from '../../ui-library/icons/NavbarIcons';
import { EventDropdownIcon } from '../../ui-library/icons/ReportIcons';
import { globalPageLoaderState } from '../global-page-loader';
import { ELEVATED_ACCESS_ROLES } from './constants';
import ThemeToggler from './ThemeToggler';

const USE_OLD_AUTH_SERVICE = true;

export default function SettingsMenu() {
  const [userName, setUserName] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [tenantId] = useState('');
  const [userData, setUserData] = useRecoilState(userInfoState);
  const [cookies, , removeCookie] = useCookies([Cookie.id_token, Cookie.refresh_token, Cookie.auth_type]);
  const queryClient = useQueryClient();
  const [darkMode] = useState(false);
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  const { mutate: updateTenantMutation } = useMutation(updateLoginTenant);

  const { data: userInfo, status } = useQuery(['userInfo', {}], getUserInfoOld, {
    enabled: sessionStorage.getItem('first_time_user') !== 'true',
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (userInfo !== undefined) {
      setUserName(`${userInfo.firstName} ${userInfo.lastName}` || '');
      setIconUrl(userInfo.profileUrl || '');
      setUserData(userInfo);
      Tracker.setUserInfo(userInfo);
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignOut = useCallback(async () => {
    setShowGlobalPageLoader(true);
    if (!USE_OLD_AUTH_SERVICE) {
      updateTenantMutation(tenantId);
    }
    Tracker.logout();
    googleLogout();
    clearActiveWorkspaceDetailsFromLocalStorage();
    removeCookie(Cookie.id_token, {
      path: '/',
    });
    removeCookie(Cookie.refresh_token, {
      path: '/',
    });
    removeCookie(Cookie.auth_type, {
      path: '/',
    });
    await queryClient.invalidateQueries();
    if (cookies[Cookie.auth_type] === OauthProvider.google) {
      googleLogout();
    }
    if (cookies[Cookie.auth_type] === OauthProvider.microsoft) {
      await msalLogout();
    }
    setShowGlobalPageLoader(false);
    window.location.href = '/login';
  }, [tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === 'error') {
      onSignOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');

    const theme = darkMode ? 'dark' : 'light';

    root.classList.add(theme);
  }, [darkMode]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="bg-transparent mx-3 outline-none focus:outline-none hover:bg-neutral-dark dark:hover:bg-neutral-dark rounded-full p-1 data-[state=open]:text-gray-300 data-[state=open]:bg-neutral-dark dark:data-[state=open]:text-gray-300 dark:data-[state=open]:bg-neutral-dark">
        <SettingsIcon className="pl-0.5 pt-[1px]" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="flex items-center">
          <Avatar>
            <AvatarImage src={iconUrl} />
            <AvatarFallback>{userName?.[0] ?? ''}</AvatarFallback>
          </Avatar>
          <div className="ml-2 text-sm font-semibold">{userName}</div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="px-2 my-2" />
        <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
          <Link
            to={getLinkWithWorkspace('custom-events/list')}
            onClick={() => {
              Tracker.trackEvent(EventNames.CUSTOM_EVENTS_PAGE_CLICKED);
            }}
            className="inline-flex items-center"
          >
            <EventDropdownIcon />
            <div className="ml-2 text-sm font-semibold">Custom Events</div>
          </Link>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="px-2 my-2" />
        {ELEVATED_ACCESS_ROLES.includes(userData?.roleName ?? '') ? (
          <>
            <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
              <Link
                to={getLinkWithWorkspace('settings/manage-users')}
                onClick={() => {
                  Tracker.trackEvent(EventNames.MANAGE_USERS_CLICKED);
                }}
                className="inline-flex items-center"
              >
                <SingleUserIcon />
                <div className="ml-2 text-sm font-semibold">Users and Teams</div>
              </Link>
            </DropdownMenuLabel>
            <DropdownMenuSeparator className="px-2 my-2" />
          </>
        ) : null}

        {ELEVATED_ACCESS_ROLES.includes(userData?.roleName ?? '') ? (
          <>
            <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
              <Link
                to={getLinkWithWorkspace('engage-settings')}
                onClick={() => {
                  Tracker.trackEvent(EventNames.ENGAGE_SETTINGS_CLICKED);
                }}
                className="inline-flex items-center"
              >
                <EngageIcon />
                <div className="ml-2 text-sm font-semibold">Engage Settings</div>
              </Link>
            </DropdownMenuLabel>
            <DropdownMenuSeparator className="px-2 my-2" />
          </>
        ) : null}

        <DropdownMenuLabel className="flex items-center justify-between mb-2">
          <p className="text-xs font-semibold">Dark Mode</p>
          <ThemeToggler />
        </DropdownMenuLabel>
        <DropdownMenuLabel
          onClick={onSignOut}
          className="flex items-center justify-between cursor-pointer rounded-md bg-transparent hover:bg-gray-50 dark:hover:bg-foreground-secondary"
        >
          <p className="text-xs font-semibold">Logout</p>
          <LogoutIcon />
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

import { Button } from '@src/client/v2/ui-library/button';
import { DashboardCardErrorSvg, RefereshIcon } from '@src/client/v2/ui-library/icons/DashboardIcons';

interface Props {
  retry?: () => void;
  msg?: string;
  error: Error | null | unknown;
}

export default function GenericErrorView({ error, msg, retry }: Props) {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <DashboardCardErrorSvg />
      <p className="text-xs text-foreground mt-3">{msg || 'Failed to retrieve the report'}</p>
      {retry ? (
        <Button variant="link" onClick={retry} className="hover:no-underline dashboard-card-action-button">
          <RefereshIcon />
          <span className="ml-2 text-primary-dark">Retry</span>
        </Button>
      ) : null}
    </div>
  );
}

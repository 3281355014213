/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@src/client/hint.css';

import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import AppErrorBoundaryFallback from './components/error-handling/AppErrorBoundaryFallback';
import Tracker from './lib/analytics/tracker';
import WebVitalsTracker from './lib/analytics/webVitalsTracker';
import { generateMsalInstance } from './lib/msal-utils';
import Router from './routes';
import GlobalPageLoader from './v2/components/global-page-loader';
import RecoilDebugObserver from './v2/components/recoil-debug-observer';
import { SpinLoader } from './v2/ui-library/loaders';
import { UIStoragKey, UIVersionProvider } from './v2/ui-library/theme-provider/uiProvider';

const queryClient = new QueryClient();

const AntdThemeObj = {
  token: {
    // colorPrimary: '#1271ed',
    colorPrimary: '#4F15FF',
    borderRadius: 4,
  },
};
const msalInstance = generateMsalInstance();

function PageLoader() {
  return (
    <div className="flex h-screen bg-background">
      <div className="h-navbar bg-neutral w-full" />
      <div className="pt-[60px] bg-background text-foreground min-h-layout-v2 box-border">
        <SpinLoader />
      </div>
    </div>
  );
}

function App() {
  const { VITE_REACT_APP_GOOGLE_CLIENT_ID, VITE_UDAAN_ENV } = import.meta.env;

  useEffect(() => {
    Tracker.init();
    WebVitalsTracker.init();
  }, []);

  return (
    <React.StrictMode>
      <ConfigProvider theme={{ ...AntdThemeObj }}>
        <RecoilRoot>
          {VITE_UDAAN_ENV === 'local' || VITE_UDAAN_ENV === 'dev' ? <RecoilDebugObserver /> : null}
          <CookiesProvider>
            <Suspense fallback={<PageLoader />}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                <GoogleOAuthProvider clientId={`${VITE_REACT_APP_GOOGLE_CLIENT_ID}`}>
                  <MsalProvider instance={msalInstance}>
                    <BrowserRouter>
                      <ErrorBoundary FallbackComponent={AppErrorBoundaryFallback}>
                        <UIVersionProvider defaultUI="v2" storageKey={UIStoragKey}>
                          <Router />
                          <GlobalPageLoader />
                        </UIVersionProvider>
                      </ErrorBoundary>
                    </BrowserRouter>
                  </MsalProvider>
                </GoogleOAuthProvider>
              </QueryClientProvider>
            </Suspense>
          </CookiesProvider>
        </RecoilRoot>
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default App;
